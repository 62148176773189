import {
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { ScopeEfficacyOutcome } from "@fdy/faraday-js";
import { ClockCountdown, XCircle } from "@phosphor-icons/react";

import { colors } from "../../../../styles/chakra-theme-v2";
import { plural } from "../../../../utils/formatters";
import { AccordionV2 } from "../../../ui/AccordionV2";
import { CardV2 } from "../../../ui/Card/CardV2";
import { IconWithText } from "../../../ui/IconWithText";
import { ResourceLink } from "../../../ui/ResourceLink";
import { PipelineFragment as Scope } from "../../__generated__/PipelineFragment";
import { useScopePayloadOutcomesQuery } from "../useScopePayloadQuery";
import { EfficacyMultiMonth } from "./EfficacyMultiMonth";
import { EfficacySingleMonth } from "./EfficacySingleMonth";
import { useScopeEfficacyQuery } from "./useScopeEfficacyQuery";

interface PipelineEfficacyCardProps {
  scope: Scope;
}

export function OutcomeEfficacyAccordionItem({
  scope,
  outcomeEfficacy,
}: {
  scope: Scope;
  outcomeEfficacy: ScopeEfficacyOutcome;
}) {
  const { data: scopePayloadOutcomes } = useScopePayloadOutcomesQuery(scope.id);
  const outcome = scopePayloadOutcomes.find((o) => o.id === outcomeEfficacy.id);
  if (!outcome) return <p>Outcome not available on account.</p>;

  const totalMonths = outcomeEfficacy.months.length;

  return (
    <AccordionV2.Item
      title={outcome.name}
      subtitle="efficacy"
      subtitleDivider=""
    >
      <Stack spacing={4} mb={4}>
        <Text>
          How did Faraday’s <ResourceLink resource={outcome} /> predictions
          align with what actually happened?
        </Text>
        <Tabs size="sm">
          <TabList>
            <Tab>Last month</Tab>

            {totalMonths > 1 && (
              <Tab>
                Last {totalMonths} {plural(totalMonths, "month", "months")}
              </Tab>
            )}
          </TabList>
          <TabPanels>
            <TabPanel>
              <EfficacySingleMonth
                efficacy={outcomeEfficacy}
                outcome={outcome}
              />
            </TabPanel>

            {totalMonths > 1 && (
              <TabPanel>
                <EfficacyMultiMonth efficacy={outcomeEfficacy} />
              </TabPanel>
            )}
          </TabPanels>
        </Tabs>
      </Stack>
    </AccordionV2.Item>
  );
}

export function PipelineEfficacyResults({ scope }: { scope: Scope }) {
  const { data: scopeEfficacy } = useScopeEfficacyQuery(scope.id);

  if (!scopeEfficacy.outcomes?.length) {
    return (
      <IconWithText>
        <ClockCountdown weight="fill" color={colors.fdy_gray[400]} size={24} />
        <Text>
          <strong>Pending.</strong> Efficacy results are not yet available.
        </Text>
      </IconWithText>
    );
  }

  return (
    <AccordionV2 defaultIndex={0}>
      {scopeEfficacy.outcomes?.map((outcome) => (
        <OutcomeEfficacyAccordionItem
          key={outcome.id}
          scope={scope}
          outcomeEfficacy={outcome}
        />
      ))}
    </AccordionV2>
  );
}

export function PipelineEfficacyCard({ scope }: PipelineEfficacyCardProps) {
  const showResults = scope.payload.outcomeIds.length > 0;

  return (
    <CardV2
      title="Efficacy"
      text={
        <>
          Review the ongoing correspondence between Faraday predictions and
          actual behavior. Only outcomes with date based attainment and
          eligibility cohorts are included in this report at this time.
        </>
      }
    >
      {showResults ? (
        <PipelineEfficacyResults scope={scope} />
      ) : (
        <IconWithText>
          <XCircle weight="fill" color={colors.fdy_gray[400]} size={24} />
          <Text>No predictions in this pipeline with efficacy reporting.</Text>
        </IconWithText>
      )}
    </CardV2>
  );
}
