import {
  Box,
  Table,
  TableCaption,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  VisuallyHidden,
} from "@chakra-ui/react";
import { ScopeEfficacyOutcome } from "@fdy/faraday-js";

import { colors } from "../../../../styles/chakra-theme-v2";
import { TextWithInfoTooltip } from "../../../ui/TextWithInfoTooltip";
import { EfficacyPerformedIcon } from "./EfficacyResult";
import { makeDecilePercents, monthNameFromNumber } from "./utils";

export function EfficacyMultiMonth({
  efficacy,
}: {
  efficacy: ScopeEfficacyOutcome;
}) {
  return (
    <Table size="sm">
      <TableCaption
        textAlign="right"
        sx={{
          captionSide: "top",
          mb: 3,
        }}
      >
        Portion of attainers in each originally predicted probability decile
      </TableCaption>
      <Thead>
        <Tr>
          <Th>Month</Th>
          <Th>Attainments</Th>
          <Th colSpan={10}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <span>Top</span>
              <span>Bottom</span>
            </Box>
          </Th>
          <Th>
            <VisuallyHidden>Performed</VisuallyHidden>
          </Th>
        </Tr>
      </Thead>
      <Tbody>
        {efficacy.months.map((month, i) => {
          const percents = makeDecilePercents(month);

          const attainmentSummary = (
            <>
              Gross attainments: {month.gross_attainments.toLocaleString()}
              <br />
              Net attainments: {month.net_attainments.toLocaleString()}
              <br />
              Attainment window: {month.attainment_window} days
            </>
          );

          return (
            <Tr key={i} _hover={{ bg: "inherit" }}>
              <Td bg={colors.fdy_gray[200]}>
                {monthNameFromNumber(month.month)} {month.year}
              </Td>
              <Td bg={colors.fdy_gray[200]}>
                <TextWithInfoTooltip
                  tooltip={attainmentSummary}
                  title={month.net_attainments.toLocaleString()}
                />
              </Td>
              {percents.map((p, i) => (
                <Td key={i} sx={p.style}>
                  {p.label}
                </Td>
              ))}
              <Td>
                <EfficacyPerformedIcon performed={month.performed} />
              </Td>
            </Tr>
          );
        })}
      </Tbody>
    </Table>
  );
}
