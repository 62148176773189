import { useCallback, useMemo } from "react";

import { TraitPermission } from "../../../__generated__/sojournerGlobalTypes";
import { Trait, useTraitsQuery } from "../../../hooks/useTraitsQuery";
import { groupAndSortTraitsByCategories } from "../../traits/traitUtils";
import {
  FdyReactSelect,
  FdySelectGroupBase,
  FdySelectOption,
} from "../../ui/FdyReactSelect";
import { FormField } from "../../ui/FormField";
import { useId } from "../../ui/useId";
import { PipelineFormState } from "./PipelineForm";
import { SetPiplineFormState } from "./PipelineFormPayloadCard";
import { pipelineHelpText } from "./pipelineHelpText";
import { ResetScopeError, ScopeErrors } from "./usePipelineValidator";

interface PayloadTraitOption extends FdySelectOption {}
interface PayloadTraitGroup extends FdySelectGroupBase<PayloadTraitOption> {}

function createPayloadTraitOptions(
  traits: Trait[],
  formState: PipelineFormState
): PayloadTraitGroup[] {
  return groupAndSortTraitsByCategories(
    traits.filter((trait) => {
      return (
        (trait.permissions.includes(TraitPermission.ADD_TO_SCOPE_PAYLOAD) &&
          !trait.deprecated) ||
        formState.attributes.find((inUseName) => inUseName === trait.name)
      );
    })
  ).map((group) => {
    const options: PayloadTraitOption[] = group.traits.map((trait) => {
      return {
        label: trait.literate,
        value: trait.name,
      };
    });

    return {
      label: group.categoryLiterate,
      options,
    };
  });
}

export function PipelineFormPayloadTraits({
  formState,
  setFormState,
  errors,
  resetError,
}: {
  formState: PipelineFormState;
  setFormState: SetPiplineFormState;
  errors: ScopeErrors | undefined;
  resetError: ResetScopeError;
}) {
  const id = useId();

  // fetch traits
  const { loadingTraits, traits } = useTraitsQuery();

  // group traits by category
  const traitGroups = useMemo(
    () => createPayloadTraitOptions(traits, formState),
    [traits]
  );

  // get selected traits to show in the select
  const value = useMemo(() => {
    const allGroupOptions = traitGroups.flatMap((g) => g.options);

    return allGroupOptions.filter((t) =>
      formState.attributes.includes(t.value)
    );
  }, [formState.attributes, traits]);

  // handle change of selected traits
  const handleChange = useCallback(
    (selectedOptions: readonly PayloadTraitOption[]) => {
      setFormState((state) => ({
        ...state,
        attributes: selectedOptions.map((opt) => opt.value),
      }));

      resetError("predictions");
      resetError("cohortMembership");
      resetError("traits");
    },
    [setFormState, resetError]
  );

  return (
    <FormField
      label="Traits"
      htmlFor={id}
      helpText={pipelineHelpText.traits}
      error={errors?.traits}
      analyticsName="traits"
    >
      <FdyReactSelect<PayloadTraitOption, true, PayloadTraitGroup>
        inputId={id}
        isMulti
        isLoading={loadingTraits}
        isDisabled={loadingTraits}
        options={traitGroups}
        value={value}
        onChange={handleChange}
        placeholder="Select traits..."
        isInvalid={Boolean(errors?.traits)}
        limitShownMultiValue={6}
        selectableGroups
      />
    </FormField>
  );
}
