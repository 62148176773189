import {
  Table,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
  VisuallyHidden,
} from "@chakra-ui/react";
import { Plus } from "@phosphor-icons/react";

import { Button } from "../../../../ui/Button";
import { SampleData } from "../shared/OptionWithSampleData";
import { DetectedColumn } from "../shared/types";
import { EventProperty } from "./DatasetsEventsModal";
import { PropertiesTableRow } from "./PropertiesTableRow";

export function PropertiesTable({
  properties,
  detectedColumns,
  disabled,
  sampleData,
  onPropertyChange,
  onRemoveProperty,
  onAddProperty,
}: {
  properties: EventProperty[];
  onPropertyChange: (property: EventProperty) => void;
  onRemoveProperty: (property: EventProperty) => void;
  onAddProperty: () => void;
  detectedColumns: DetectedColumn[];
  disabled?: boolean;
  sampleData?: SampleData;
}) {
  return (
    <Table my={4} size="xs">
      <Thead>
        <Tr>
          <Th>Field in dataset</Th>
          <Th>Property name</Th>
          <Th>Format (optional)</Th>
          <Th>
            <VisuallyHidden>Delete</VisuallyHidden>
          </Th>
        </Tr>
      </Thead>

      <Tbody
        sx={{
          // max width with pxs works better in FF than % on the table headings
          td: { maxWidth: 200 },
        }}
      >
        {properties.map((property, i) => (
          <PropertiesTableRow
            index={i}
            key={property.id}
            property={property}
            onChange={onPropertyChange}
            onRemove={onRemoveProperty}
            detectedColumns={detectedColumns}
            disabled={disabled}
            sampleData={sampleData}
            locked={property.locked}
          />
        ))}
      </Tbody>
      <Tfoot>
        <Tr>
          <Td colSpan={4} p={2} pl={4}>
            <Button
              variant="link"
              leftIcon={<Plus weight="bold" />}
              justifyContent="left"
              width="100%"
              disabled={disabled}
              onClick={onAddProperty}
              analyticsName="add-event-property"
            >
              Add property
            </Button>
          </Td>
        </Tr>
      </Tfoot>
    </Table>
  );
}
