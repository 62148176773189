import { Trait, TraitCategory, TraitTier } from "@fdy/faraday-js";
import groupBy from "lodash/groupBy";
import upperFirst from "lodash/upperFirst";

import {
  TraitCategory as GqlTraitCategory,
  TraitPermission as GqlTraitPermission,
} from "../../__generated__/sojournerGlobalTypes";
import { Trait as GqlTrait, TraitsMap } from "../../hooks/useTraitsQuery";

/**
 * Render the trait literate if appropriate and available, otherwise return the trait name.
 */
export function traitLiterate(
  trait:
    | {
        name: string;
        literate: string | null;
        category: GqlTraitCategory | null;
      }
    | Pick<Trait, "name" | "literate" | "category">
) {
  // users don't set literates for user defined traits so we use the name they set
  if (
    trait.category?.toLowerCase() === TraitCategory.UserDefined.toLowerCase()
  ) {
    return trait.name;
  }

  return trait.literate ?? trait.name;
}

export function traitLiterateFromMap(name: string, traitsMap: TraitsMap) {
  const trait = traitsMap[name];
  return trait ? traitLiterate(trait) : name;
}

export function traitCategoryToLiterate(
  categoryName: GqlTraitCategory | TraitCategory | null | undefined
): string {
  const catLower = categoryName?.toLowerCase();
  if (!catLower) return "";
  if (catLower === GqlTraitCategory.USER_DEFINED.toLowerCase())
    return "User defined";
  return upperFirst(
    catLower
      .toLowerCase()
      .replace(/^fig(_|\/)/, "")
      .replace(/_/g, " ")
  );
}

export function traitTierToLiterate(tierName: TraitTier | undefined): string {
  if (!tierName) return "";
  return upperFirst(tierName.toLowerCase());
}

/**
 * Group traits by category and sort them so user defined are first.
 */
export function groupAndSortTraitsByCategories(
  traits: Pick<GqlTrait, "name" | "literate" | "category" | "permissions">[]
): {
  categoryLiterate: string;
  traits: {
    name: string;
    literate: string;
    permissions: GqlTraitPermission[];
  }[];
}[] {
  const grouped = groupBy(
    traits,
    (t) => t.category ?? GqlTraitCategory.USER_DEFINED
  );

  return Object.entries(grouped)
    .sort(([a], [b]) => {
      if (a === GqlTraitCategory.USER_DEFINED) {
        return -1;
      }
      if (b === GqlTraitCategory.USER_DEFINED) {
        return 1;
      }
      return a.localeCompare(b);
    })
    .map(([category, traits]) => {
      return {
        categoryLiterate: traitCategoryToLiterate(category as GqlTraitCategory),
        traits: traits
          .map((t) => ({
            name: t.name,
            literate: traitLiterate(t),
            permissions: t.permissions,
          }))
          .sort((a, b) => a.literate.localeCompare(b.literate)),
      };
    });
}

export const allTraitPermissions: GqlTraitPermission[] = [
  GqlTraitPermission.ADD_TO_SCOPE_PAYLOAD,
  GqlTraitPermission.DEFINE_COHORT,
];
