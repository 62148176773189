import { Dispatch, SetStateAction } from "react";

import {
  CohortTraitInput,
  PrimitiveDataType,
} from "../../../../__generated__/sojournerGlobalTypes";
import { Trait } from "../../../../hooks/useTraitsQuery";
import { CohortFilterEditor } from "../CohortFilterEditor";
import {
  booleanTraitToWire,
  traitConditionsFromWire,
  traitConditionsToWire,
} from "./utils";

export interface CohortTraitsEditorProps {
  trait: Trait;
  condition: CohortTraitInput;
  onChange: Dispatch<SetStateAction<CohortTraitInput>>;
}

/** If the trait has a lookup table, we want to apply it so the user
 * friendly categories are shown in the UI. This could transform values
 * that are numbers with breaks like 1,2,3,4 to Definite Renter, Probable
 * Renter, Probable Owner, Definite Owner. This is so modeling can be as
 * specific as possible maintaining relationship between values, but users
 * still understand what is happening. */
function applyLookupTableToTrait(trait: Trait): Trait {
  const traitWithFilterProperties = { ...trait };

  if (trait.lookupTable) {
    traitWithFilterProperties.categories = Object.values(
      trait.lookupTable
    ) as string[];
    traitWithFilterProperties.type = PrimitiveDataType.STRING;
  }

  return traitWithFilterProperties;
}

export function CohortTraitsEditor(props: CohortTraitsEditorProps) {
  return (
    <CohortFilterEditor<CohortTraitInput>
      filterName={props.trait.name}
      filterProperties={applyLookupTableToTrait(props.trait)}
      condition={props.condition}
      onChange={props.onChange}
      conditionsToWire={(condition, conditionsList) =>
        traitConditionsToWire(props.trait, condition, conditionsList)
      }
      conditionsFromWire={(condition) =>
        traitConditionsFromWire(props.trait, condition)
      }
      booleanConditionToWire={booleanTraitToWire}
    />
  );
}
