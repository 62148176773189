import { gql } from "@apollo/client";

import { traitParamKeys } from "../components/traits/TraitFilters";
import {
  COHORTS_ENABLED,
  CONNECTIONS_ENABLED,
  CUSTOM_GEOGRAPHY_ENABLED,
  DATASETS_ENABLED,
  EVENTS_ENABLED,
  EXPLORE_ENABLED,
  FILES_ENABLED,
  INFORM_ENABLED,
  LOCATIONS_ENABLED,
  MEMBERS_ENABLED,
  OUTCOMES_ENABLED,
  PERSONAS_ENABLED,
  PIPELINES_ENABLED,
  RECIPES_ENABLED,
  RECOMMENDERS_ENABLED,
  TRAITS_ENABLED,
} from "./accountFeatureNames";
import * as r from "./routeNames";

class RoutingError extends Error {
  constructor(reason, redirect) {
    super(reason);
    this.why = reason;
    this.redirect = redirect;
  }
}

// Ensure param is a uuid for funzies:
const uuid = (name) =>
  `${name}<[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}>`;

export const ROUTER_REDIRECT_FRAGMENT = gql`
  fragment RouterRedirectFragment on Query {
    account {
      id
      name
      features
      config
      setup_status
    }
    user {
      id
      admin
      email
    }
  }
`;

function getRouterRedirectData(gqlClient) {
  return gqlClient
    .query({
      query: gql`
        query routerRedirectQuery {
          ...RouterRedirectFragment
        }
        ${ROUTER_REDIRECT_FRAGMENT}
      `,
      fetchPolicy: "cache-first",
    })
    .then(({ data }) => data);
}

const requireAccountFeature =
  (featureName) =>
  (router, dependencies) =>
  async (_fromState, _toState, _done) => {
    const { account, user } = await getRouterRedirectData(
      dependencies.gqlClient
    );
    if (user.admin || account.config[featureName]) {
      return true;
    } else {
      throw new RoutingError(
        `Missing feature ${featureName} for account ${account.name}`,
        {
          name: r.HOME,
        }
      );
    }
  };

// this isn't used 100% of the time, if routes are meant to temporarily be locked behind a feature flag
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const requireFeature =
  (featureName) =>
  (router, dependencies) =>
  async (_fromState, _toState, _done) => {
    const { account, user } = await getRouterRedirectData(
      dependencies.gqlClient
    );
    const features = account.features.reduce(
      (acc, p) => ({ ...acc, [p]: true }),
      {}
    );
    // Admins can go anywhere (no guarantee the view will function)
    if (user.admin) {
      return true;
    } else {
      return featureName in features;
    }
  };

export const ROUTES = [
  {
    name: r.HOME,
    path: "/",
    async redirect({ router, ...dependencies }) {
      const { account } = await getRouterRedirectData(dependencies.gqlClient);

      const pipelinesEnabled = account.config[PIPELINES_ENABLED];
      const recipesEnabled = account.config[RECIPES_ENABLED];

      if (account.setup_status === "onboarding") {
        return { name: r.ROUTE_NAMES.ONBOARDING };
      } else if (pipelinesEnabled) {
        return { name: r.ROUTE_NAMES.PIPELINES };
      } else if (recipesEnabled) {
        return { name: r.ROUTE_NAMES.RECIPES };
      } else {
        return { name: r.ROUTE_NAMES.SETTINGS };
      }
    },
  },
  {
    name: r.LOGOUT,
    path: "/logout",
    async redirect({ auth }) {
      await auth.logout(); // the promise returned by logout never resolves
    },
  },
  {
    name: r.SETTINGS,
    path: "/settings",
    children: [
      {
        name: r.ACCOUNT,
        path: "/account",
      },
      {
        name: r.FILES,
        path: "/files",
        canActivate: requireAccountFeature(FILES_ENABLED),
        children: [{ name: r.FILE, path: uuid("/:id") }],
      },
      {
        name: r.MEMBERS,
        path: "/members",
        canActivate: requireAccountFeature(MEMBERS_ENABLED),
      },
      {
        name: r.GEOGRAPHIES,
        path: "/geographies",
        canActivate: requireAccountFeature(CUSTOM_GEOGRAPHY_ENABLED),
        children: [{ name: r.ID, path: uuid("/:id") }],
      },
      {
        name: r.API,
        path: "/api?:paymentAdded",
      },
      {
        name: r.BILLING,
        path: "/billing",
      },
    ],
  },
  {
    name: r.EXPLORE,
    path: "/explore",
    canActivate: requireAccountFeature(EXPLORE_ENABLED),
    redirect() {
      return {
        name: r.ROUTE_NAMES.EXPLORE_ANALYSIS,
      };
    },
    children: [
      {
        name: r.ANALYSIS,
        path: "/analysis?id?segment",
      },
    ],
  },
  {
    name: r.INFORM,
    path: "/inform",
    canActivate: requireAccountFeature(INFORM_ENABLED),
    redirect() {
      return {
        name: r.ROUTE_NAMES.INFORM_PAGE,
        params: {
          page: 1,
        },
      };
    },
    children: [
      {
        name: r.PAGE,
        path: "/:page",
      },
    ],
  },
  {
    name: r.LOCATIONS,
    path: "/locations",
    canActivate: requireAccountFeature(LOCATIONS_ENABLED),
  },
  {
    name: r.PIPELINES,
    path: "/pipelines",
    canActivate: requireAccountFeature(PIPELINES_ENABLED),
    children: [
      {
        name: r.NEW,
        path: "/new",
      },
      {
        name: r.ID,
        path: uuid("/:id") + "?:recipe",
        children: [
          {
            name: r.EDIT,
            path: "/edit",
          },
          {
            name: r.ANALYSIS,
            path: "/analysis",
          },
        ],
      },
    ],
  },
  {
    name: r.CONNECTIONS,
    path: "/connections",
    canActivate: requireAccountFeature(CONNECTIONS_ENABLED),
    children: [
      {
        name: r.NEW,
        path: "/new",
      },
      {
        name: r.ID,
        path: uuid("/:id"),
        children: [
          {
            name: r.EDIT,
            path: "/edit",
          },
        ],
      },
    ],
  },
  {
    name: r.PERSONAS,
    path: "/personas",
    canActivate: requireAccountFeature(PERSONAS_ENABLED),
    children: [
      {
        name: r.NEW,
        path: "/new",
      },
      {
        name: r.ID,
        path: uuid("/:roster"),
        children: [
          {
            name: r.ANALYSIS,
            path: "/analysis",
          },
          {
            name: r.EDIT,
            path: "/edit",
          },
          {
            name: r.FLOW,
            path: "/flow",
          },
        ],
      },
    ],
  },
  {
    name: r.COHORTS,
    path: "/cohorts",
    canActivate: requireAccountFeature(COHORTS_ENABLED),
    children: [
      {
        name: r.ID,
        path: uuid("/:cohort"),
        children: [
          {
            name: r.ANALYSIS,
            path: "/analysis",
          },
        ],
      },
      {
        name: r.NEW,
        path: "/new?:duplicate",
      },
    ],
  },
  {
    name: r.OUTCOMES,
    path: "/outcomes",
    canActivate: requireAccountFeature(OUTCOMES_ENABLED),
    children: [
      {
        name: r.ID,
        path: uuid("/:outcome"),
        children: [
          {
            name: r.EDIT,
            path: "/edit",
          },
        ],
      },
      {
        name: r.NEW,
        path: "/new?:attainment&:attrition&:eligibility",
      },
    ],
  },
  {
    name: r.DATASETS,
    path: "/datasets",
    canActivate: requireAccountFeature(DATASETS_ENABLED),
    children: [
      {
        name: r.NEW,
        path: "/new",
      },
      {
        name: r.ID,
        path: uuid("/:id"),
        children: [
          {
            name: r.DATA,
            path: "/data",
          },
        ],
      },
    ],
  },
  {
    name: r.EVENTS,
    path: "/events",
    canActivate: requireAccountFeature(EVENTS_ENABLED),
    children: [
      {
        name: r.ID,
        path: uuid("/:id"),
      },
    ],
  },
  {
    name: r.TRAITS,
    path: `/traits?:page?:${traitParamKeys.join("?:")}`,
    canActivate: requireAccountFeature(TRAITS_ENABLED),
    children: [
      {
        name: r.ID,
        path: uuid("/:id"),
      },
    ],
  },

  {
    name: r.RECIPES,
    path: "/recipes",
    children: [
      {
        name: r.RECIPE,
        path: "/:recipe",
      },
    ],
  },
  {
    name: r.RECOMMENDERS,
    path: "/recommenders",
    canActivate: requireAccountFeature(RECOMMENDERS_ENABLED),
    children: [
      {
        name: r.NEW,
        path: "/new",
      },
      {
        name: r.ID,
        path: uuid("/:id"),
        children: [
          {
            name: r.EDIT,
            path: "/edit",
          },
        ],
      },
    ],
  },
  {
    name: r.ONBOARDING,
    path: "/onboarding",
  },
];

export const CONFIG = {
  defaultRoute: r.HOME,
  defaultParams: { params: "" },
  useHash: false,
};
