import { Text } from "@chakra-ui/react";
import { ScopeEfficacyOutcomeMonth } from "@fdy/faraday-js";

import { colors } from "../../../../styles/chakra-theme-v2";

export function AttainmentWindowNote({
  month,
}: {
  month: ScopeEfficacyOutcomeMonth;
}) {
  if (!month.attainment_window) return null;
  return (
    <Text color={colors.fdy_gray[600]} textAlign="center">
      Reporting assumes a <strong>{month.attainment_window}-day</strong>{" "}
      attainment window, which was met by 80% of attainers.
    </Text>
  );
}
