import { ButtonGroup } from "@chakra-ui/react";
import { ReactElement, ReactNode } from "react";
import { useRouter } from "react-router5";

import { ROUTE_NAMES } from "../../constants/routeNames";
import { AlertStack } from "../ui/AlertStack";
import { DependencyStatusAlert } from "../ui/DependencyStatusAlert";
import { PageContainer } from "../ui/PageContainer";
import { Breadcrumb, PageHeader } from "../ui/PageHeader";
import { PageLayout } from "../ui/PageLayout";
import { ResourceDocsLink } from "../ui/ResourceDocsLink";
import { ResourceArchivedAlert } from "../ui/ResourceStatus/ResourceArchivedAlert";
import { ResourceStatusAlert } from "../ui/ResourceStatus/ResourceStatusAlert";
import { SidebarLayout } from "../ui/SidebarLayout/SidebarLayout";
import { useSidebarLayoutState } from "../ui/SidebarLayout/useSidebarLayoutState";
import { CohortFragment } from "./__generated__/CohortFragment";
import { CohortActionsMenu } from "./CohortActionsMenu";
import { CohortsInfoSidebar } from "./CohortsInfoSidebar";
import { CohortTabs } from "./CohortTabs";

interface CohortsSidebarLayoutProps {
  title: string;
  cohort?: CohortFragment;
  children: ReactNode;
  lastCrumb?: Breadcrumb;
}

/**
 * Renders the shared elements of a cohort show, edit, and create page.
 */
export function CohortsSidebarLayout({
  title,
  cohort,
  children,
  lastCrumb,
}: CohortsSidebarLayoutProps): ReactElement {
  const router = useRouter();

  const sidebarProps = useSidebarLayoutState("cohorts-right", {
    defaultIsOpen: false,
  });

  const crumbs = [
    {
      label: "Cohorts",
      routeName: ROUTE_NAMES.COHORTS,
    },
  ];

  if (lastCrumb) crumbs.push(lastCrumb);

  const actions = (
    <ButtonGroup>
      <ResourceDocsLink resourceType="cohorts" size="sm" />
      {cohort ? (
        <CohortActionsMenu
          cohort={cohort}
          options={{
            info: sidebarProps.onToggle,
            view: false,
            rename: cohort.classic !== true,
            api: true,
            duplicate: true,
            delete: true,
            archive: true,
          }}
          onDeleteComplete={() => {
            router.navigate(ROUTE_NAMES.COHORTS);
          }}
        />
      ) : (
        <CohortActionsMenu
          options={{
            info: sidebarProps.onToggle,
          }}
        />
      )}
    </ButtonGroup>
  );
  return (
    <PageLayout analyticsStackName="cohorts">
      <PageHeader
        title={title}
        rightContent={actions}
        crumbs={crumbs}
        backBtnProps={{
          label: "Back to Cohorts",
          routeName: ROUTE_NAMES.COHORTS,
        }}
      />
      <SidebarLayout
        rightSidebarProps={{
          isOpen: sidebarProps.isOpen,
          onClose: sidebarProps.onToggle,
          title: "About cohorts",
          children: <CohortsInfoSidebar />,
          width: 360,
        }}
      >
        <PageContainer>
          {cohort ? (
            <>
              <CohortTabs cohort={cohort} />
              <AlertStack>
                <ResourceArchivedAlert resource={cohort} />
                <DependencyStatusAlert resource={cohort} />
                <ResourceStatusAlert resource={cohort} />
              </AlertStack>
            </>
          ) : null}

          {children}
        </PageContainer>
      </SidebarLayout>
    </PageLayout>
  );
}
