import { ScopeEfficacyOutcome } from "@fdy/faraday-js";

import { colors } from "../../../../styles/chakra-theme-v2";
import { percent } from "../../../../utils/formatters";

export function getDecileStyle(n: number) {
  if (n >= 0.16)
    return {
      bg: colors.fdy_green[600],
      color: colors.white,
    };
  if (n >= 0.11)
    return {
      bg: colors.fdy_green[500],
      color: colors.black,
    };
  return {
    bg: colors.white,
    color: colors.black,
  };
}

export function monthNameFromNumber(month: number) {
  return new Date(0, month - 1, 1).toLocaleString("default", {
    month: "long",
  });
}

export function makeDecilePercents(month: ScopeEfficacyOutcome["months"][0]) {
  return month.net_attainments_by_decile.map((count) => {
    const percentOfAttainments = count / month.net_attainments;
    return {
      value: count,
      label: percent(percentOfAttainments),
      style: getDecileStyle(percentOfAttainments),
    };
  });
}
