import { Box, Flex, Text } from "@chakra-ui/react";
import { ScopeEfficacyOutcome } from "@fdy/faraday-js";
import { ArrowBendLeftUp, ArrowBendRightUp } from "@phosphor-icons/react";

import { AttainmentWindowNote } from "./AttainmentWindowNote";
import { EfficacyResult } from "./EfficacyResult";
import { makeDecilePercents, monthNameFromNumber } from "./utils";

function MonthArrowBox({
  children,
  direction,
}: {
  direction: "up-left" | "up-right";
  children: React.ReactNode;
}) {
  const base = { display: "flex", gap: 4, p: 4, maxWidth: 400 };
  const iconSize = 32;

  if (direction === "up-left") {
    return (
      <Box sx={base}>
        <div>
          <ArrowBendLeftUp size={iconSize} />
        </div>
        <div>{children}</div>
      </Box>
    );
  } else if (direction === "up-right") {
    return (
      <Box
        sx={{
          ...base,
          textAlign: "right",
        }}
      >
        <div>{children}</div>
        <div>
          <ArrowBendRightUp size={iconSize} />
        </div>
      </Box>
    );
  }

  return null;
}

export function EfficacySingleMonth({
  efficacy,
  outcome,
}: {
  efficacy: ScopeEfficacyOutcome;
  outcome: { id: string; name: string };
}) {
  const month = efficacy.months[0];
  const percents = makeDecilePercents(month);

  return (
    <div>
      <EfficacyResult outcome={outcome} performed={month.performed} />

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mb: 3,
        }}
      >
        <span>Top decile</span>
        <span>Bottom decile</span>
      </Box>

      <Flex>
        {percents.map((d, i) => (
          <Box
            key={i}
            sx={{
              flex: 1,
              textAlign: "center",
              p: 2,
              border: "1px solid",
              borderColor: "fdy_gray.200",
              ...d.style,
            }}
          >
            {d.label}
          </Box>
        ))}
      </Flex>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          gap: 8,
        }}
      >
        <MonthArrowBox direction="up-left">
          In {monthNameFromNumber(month.month)}, {percents[0].label} of{" "}
          <Text as="span" fontWeight="medium">
            {outcome.name}
          </Text>{" "}
          attainers had recieved our very highest scores
        </MonthArrowBox>
        <MonthArrowBox direction="up-right">
          While only {percents.at(-1)?.label} of attainers received our very
          lowest scores
        </MonthArrowBox>
      </Box>
      <AttainmentWindowNote month={month} />
    </div>
  );
}
