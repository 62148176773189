import {
  IconButton,
  IconButtonProps,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
} from "@chakra-ui/react";
import {
  Recommender,
  RecommenderMergePatch,
  ResourceType,
} from "@fdy/faraday-js";
import { DotsThreeOutline } from "@phosphor-icons/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useRef } from "react";

import { ROUTE_NAMES } from "../../constants/routeNames";
import { useFdyClient } from "../../services/FdyClientProvider";
import { ActionMenuChiefLink } from "../ui/ActionMenuChiefLink";
import { ApiShortcutsModal } from "../ui/ApiDocs/ApiShortcutsModal";
import { Link } from "../ui/Link";
import { RenameModal } from "../ui/RenameModal";
import { ArchiveMenuButton } from "../ui/ResourceArchiveButton";
import { ResourceDeleteButton } from "../ui/ResourceDeleteButton";
import { restResourceStatusToGqlResourceStatus } from "../ui/ResourceStatus/normalizeResourceDetails";
import { recommenderKeys } from "./recommenderKeys";

type ActionMenuOptions = {
  view?: boolean;
  info?: () => void;
  archive?: boolean;
};

const defaultOptions = {
  view: false,
  archive: false,
};

export function RecommenderActionsMenu({
  recommender,
  options,
  onDeleteComplete,
  buttonProps,
}: {
  recommender?: Recommender;
  options?: ActionMenuOptions;
  onDeleteComplete?: () => void;
  buttonProps?: Pick<IconButtonProps, "my" | "variant">;
}) {
  const enabledOptions = { ...defaultOptions, ...options };
  const renameModalState = useDisclosure();
  const apiShortcutsModalProps = useDisclosure();
  const menuBtnRef = useRef<HTMLButtonElement>(null);
  const label = recommender
    ? `Toggle ${recommender.name} actions`
    : `Toggle actions`;

  const client = useFdyClient();
  const queryClient = useQueryClient();

  const updateRecommenderMutation = useMutation({
    mutationFn: (data: RecommenderMergePatch) =>
      recommender?.id
        ? client.recommenders.updateRecommender(recommender.id, data)
        : Promise.reject("No recommender id"),
    onSuccess: () => {
      renameModalState.onClose();
      queryClient.invalidateQueries({
        queryKey: recommenderKeys.list,
      });
    },
  });

  return (
    <>
      <Menu>
        <MenuButton
          as={IconButton}
          ref={menuBtnRef}
          aria-label={label}
          icon={<DotsThreeOutline weight="fill" />}
          variant="secondary"
          color="fdy_gray.600"
          size="sm"
          {...buttonProps}
        />
        <MenuList>
          {enabledOptions.info && (
            <MenuItem onClick={enabledOptions.info}>
              About recommenders
            </MenuItem>
          )}

          {enabledOptions.view && recommender && (
            <MenuItem
              as={Link}
              routeName={ROUTE_NAMES.RECOMMENDERS_SHOW}
              params={{ id: recommender.id }}
            >
              View details
            </MenuItem>
          )}

          {recommender?.report_url && (
            <MenuItem as="a" href={recommender.report_url}>
              Technical report
            </MenuItem>
          )}

          {recommender && (
            <MenuItem
              as={Link}
              routeName={ROUTE_NAMES.RECOMMENDERS_EDIT}
              params={{ id: recommender.id }}
            >
              Edit
            </MenuItem>
          )}

          {recommender && (
            <MenuItem onClick={renameModalState.onOpen}>Rename</MenuItem>
          )}

          {recommender && (
            <MenuItem onClick={apiShortcutsModalProps.onOpen}>
              API shortcuts
            </MenuItem>
          )}

          {recommender && <ActionMenuChiefLink resource={recommender} />}

          {recommender && (
            <ArchiveMenuButton
              name={recommender.name}
              resourceId={recommender.id}
              resourceType="recommenders"
              archivedAt={recommender.archived_at}
              status={restResourceStatusToGqlResourceStatus(recommender.status)}
            />
          )}

          {recommender && (
            <ResourceDeleteButton
              name={recommender.name}
              resourceId={recommender.id}
              resourceType="recommenders"
              onDeleteComplete={onDeleteComplete}
              resourceStatus={recommender.status}
              listQueryKey={recommenderKeys.list}
              isMenuItem
            />
          )}
        </MenuList>
      </Menu>

      {/* must be outside Menu otherwise pressing tab inside the Modal doesn't work */}

      {renameModalState.isOpen && recommender && (
        <RenameModal
          title="Rename recommender"
          defaultValue={recommender.name}
          onClose={renameModalState.onClose}
          updateFn={(name) => updateRecommenderMutation.mutateAsync({ name })}
          updating={updateRecommenderMutation.isPending}
          focusAfterCloseRef={menuBtnRef}
        />
      )}

      {apiShortcutsModalProps.isOpen && recommender && (
        <ApiShortcutsModal
          resource={{
            id: recommender.id,
            resource_type: ResourceType.Recommenders,
          }}
          onClose={apiShortcutsModalProps.onClose}
        />
      )}
    </>
  );
}
