import {
  Box,
  Checkbox,
  Flex,
  Spacer,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { ResourceType } from "@fdy/faraday-js";
import { CaretUp, CaretUpDown } from "@phosphor-icons/react";
import { CaretDown } from "@phosphor-icons/react/dist/ssr";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  Header,
  PaginationState,
  Row,
  RowData,
  RowSelectionState,
  SortingState,
  TableState,
  useReactTable,
  VisibilityState,
} from "@tanstack/react-table";
import React, { Dispatch, SetStateAction, useMemo, useState } from "react";

import { BlankslateProps } from "../Blankslate";
import { IconWithText } from "../IconWithText";
import { MultiArchiveButton } from "../MultiResourceArchiveButton";
import { Pagination } from "../Pagination-v2/Pagination";
import { TableBlankslate, TableLoading } from "../TableV2/parts";
import { TextWithInfoTooltip } from "../TextWithInfoTooltip";
import {
  filterResourceTabs,
  ResourceForTabFilter,
  ResourceTab,
  ResourceTableTabs,
} from "./ResourceTableTabs";
import { useLocalStorageSortingState } from "./useLocalStorageSortingState";

declare module "@tanstack/react-table" {
  //allows us to define custom properties for our columns
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface ColumnMeta<TData extends RowData, TValue> {
    headerTooltip?: string;
  }
}

export interface ResourceTableProps<T> {
  resourceType: ResourceType;
  columns: ColumnDef<T>[];
  data: T[];
  defaultSort: SortingState;
  columnVisibility?: VisibilityState;
  loading?: boolean;
  blankslate?: BlankslateProps;
  canSelectRow?: (row: Row<T>) => boolean;
  pagination?: {
    paginationState: PaginationState;
    setPagination: Dispatch<SetStateAction<PaginationState>>;
  };
}

const sortIcons = {
  default: <CaretUpDown />,
  asc: <CaretUp />,
  desc: <CaretDown />,
};

const MaybeTooltip = ({
  tooltip,
  children,
}: {
  tooltip?: string;
  children: React.ReactNode;
}) => {
  if (tooltip) {
    return <TextWithInfoTooltip title={children} tooltip={tooltip} />;
  }

  return <>{children}</>;
};

const TableHeadCell = <TData extends RowData, TValue>({
  header,
}: {
  header: Header<TData, TValue>;
}) => {
  const labelNode = (
    <MaybeTooltip tooltip={header.column.columnDef.meta?.headerTooltip}>
      {flexRender(header.column.columnDef.header, header.getContext())}
    </MaybeTooltip>
  );

  if (header.column.getCanSort()) {
    const sortDir = header.column.getIsSorted();
    const sortIcon = sortDir ? sortIcons[sortDir] : sortIcons.default;

    return (
      <Th
        key={header.id}
        style={{
          width: header.column.columnDef.size,
        }}
      >
        <button
          onClick={header.column.getToggleSortingHandler()}
          aria-sort={sortDir === "asc" ? "ascending" : "descending"}
          style={{ fontWeight: "inherit" }}
        >
          <IconWithText>
            {labelNode}
            {sortIcon}
          </IconWithText>
        </button>
      </Th>
    );
  }

  return (
    <Th
      key={header.id}
      style={{
        width: header.column.columnDef.size,
      }}
    >
      {labelNode}
    </Th>
  );
};

/**
 * Renders a table with a header and body, using the react-table library.
 * - Above the table is a set of tabs that filter the data based on the status of the resource.
 * - Headers are sortable. State is stored in local storage.
 * - Rows can be selected with checkboxes and actions can be taken on selected rows (archiving only supported for now).
 */
export const ResourceTable = <T extends ResourceForTabFilter>({
  columns,
  data,
  defaultSort,
  columnVisibility,
  loading,
  blankslate,
  resourceType,
  canSelectRow,
  pagination,
}: ResourceTableProps<T>) => {
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

  const [activeTab, setActiveTab] = useState<ResourceTab>(ResourceTab.All);

  const filteredData = useMemo(
    () => filterResourceTabs(data, activeTab),
    [data, activeTab]
  );

  const [sorting, setSorting] = useLocalStorageSortingState(
    resourceType,
    defaultSort
  );

  const columnsWithRowSelection = useMemo<ColumnDef<T>[]>(() => {
    return [
      {
        id: "select",
        size: 24,
        header: ({ table }) => (
          <Checkbox
            isIndeterminate={table.getIsSomeRowsSelected()}
            isChecked={table.getIsAllRowsSelected()}
            onChange={table.getToggleAllRowsSelectedHandler()}
            aria-label="Select all rows"
          />
        ),
        cell: ({ row }) => (
          <Checkbox
            isChecked={row.getIsSelected()}
            onChange={row.getToggleSelectedHandler()}
            isDisabled={row.getCanSelect() === false}
          />
        ),
      },
      ...columns,
    ];
  }, [columns, rowSelection]);

  const tableState = useMemo<Partial<TableState>>(() => {
    const state: Partial<TableState> = {
      sorting,
      rowSelection,
    };

    if (columnVisibility) {
      state.columnVisibility = columnVisibility;
    }

    if (pagination) {
      state.pagination = pagination.paginationState;
    }

    return state;
  }, [sorting, columnVisibility, rowSelection, pagination]);

  const table = useReactTable<T>({
    data: filteredData,
    columns: columnsWithRowSelection,
    getRowId: (row) => row.id,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: pagination ? getPaginationRowModel() : undefined,
    enableMultiRowSelection: activeTab !== ResourceTab.Archived,
    enableRowSelection: (row) => {
      if (activeTab === ResourceTab.Archived) return false;
      if (canSelectRow) return canSelectRow(row);
      return true;
    },
    state: tableState,
    onPaginationChange: pagination?.setPagination,
    onSortingChange: setSorting,
    onRowSelectionChange: setRowSelection,
  });

  const selectedIds = useMemo(
    () =>
      Object.entries(rowSelection)
        .map(([id, isSelected]) => (isSelected ? id : null))
        .filter((t) => t !== null) as string[],
    [rowSelection]
  );

  const { rows } = table.getRowModel();

  return (
    <>
      <Flex justifyContent="space-between">
        <ResourceTableTabs
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          data={data}
        />

        {selectedIds.length > 0 && (
          <Flex
            sx={{
              gap: 4,
              alignItems: "center",
            }}
          >
            <Box>{selectedIds.length} selected</Box>
            <MultiArchiveButton
              resources={data}
              ids={selectedIds}
              resourceType={resourceType}
              onSettled={() => {
                table.resetRowSelection();
              }}
            />
          </Flex>
        )}
      </Flex>

      <Table>
        <Thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <TableHeadCell key={header.id} header={header} />
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {loading ? (
            <TableLoading />
          ) : rows.length === 0 ? (
            <TableBlankslate {...blankslate} />
          ) : (
            rows.map((row) => (
              <Tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <Td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Td>
                ))}
              </Tr>
            ))
          )}
        </Tbody>
      </Table>

      {pagination?.paginationState && (
        <>
          <Spacer my={4} />

          <Pagination
            disabled={loading}
            total={table.getRowCount()}
            pageSize={pagination.paginationState.pageSize}
            current={pagination.paginationState.pageIndex + 1}
            onChange={(newPage) => {
              table.setPageIndex(newPage - 1);
            }}
          />
        </>
      )}
    </>
  );
};
