import { SortingState } from "@tanstack/react-table";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useLocalStorage } from "react-use";

/**
 * Sorting state that is stored in local storage.
 */
export const useLocalStorageSortingState = (
  storageKey: string,
  defaultSort: SortingState
): [SortingState, Dispatch<SetStateAction<SortingState>>] => {
  const [sortConfigStored, setSortConfig] = useLocalStorage<SortingState>(
    `sorting_state__${storageKey}`,
    defaultSort
  );

  // somewhat redundant state because react-table doesn't like state setter from useLocalStorage
  const [sorting, setSorting] = useState<SortingState>(
    sortConfigStored ?? defaultSort
  );

  useEffect(() => {
    setSortConfig(sorting);
  }, [sorting]);

  return [sorting, setSorting];
};
