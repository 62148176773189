import {
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
} from "@chakra-ui/react";
import {
  ResourceStatus,
  ResourceType,
  Trait,
  TraitCategory,
} from "@fdy/faraday-js";
import { DotsThreeOutline } from "@phosphor-icons/react";
import { useQueryClient } from "@tanstack/react-query";
import * as React from "react";

import { traitQueryKeys } from "../../hooks/api";
import { useAdmin } from "../../hooks/useAdmin";
import { ActionMenuChiefLink } from "../ui/ActionMenuChiefLink";
import { ApiShortcutsModal } from "../ui/ApiDocs/ApiShortcutsModal";
import { ArchiveMenuButton } from "../ui/ResourceArchiveButton";
import { ResourceDeleteButton } from "../ui/ResourceDeleteButton";
import { restResourceStatusToGqlResourceStatus } from "../ui/ResourceStatus/normalizeResourceDetails";
import { traitLiterate } from "./traitUtils";

export function TraitActionsMenu({
  trait,
  buttonProps,
}: {
  trait: Trait;
  buttonProps?: { my: number; variant: string };
}) {
  const queryClient = useQueryClient();
  const menuBtnRef = React.useRef<HTMLButtonElement>(null);
  const apiShortcutsModalProps = useDisclosure();
  const { admin } = useAdmin();

  const userDefined = trait.category === TraitCategory.UserDefined;

  // admins can always see the menu to access chief link
  if (!userDefined && !admin) return null;

  return (
    <>
      <Menu>
        <MenuButton
          as={IconButton}
          ref={menuBtnRef}
          aria-label={`Toggle ${traitLiterate(trait)} actions`}
          icon={<DotsThreeOutline weight="fill" />}
          variant="secondary"
          color="fdy_gray.600"
          size="sm"
          {...buttonProps}
        />
        <MenuList>
          {userDefined && (
            <MenuItem onClick={apiShortcutsModalProps.onOpen}>
              API Shortcuts
            </MenuItem>
          )}

          <ActionMenuChiefLink resource={trait} />

          {userDefined && (
            <ArchiveMenuButton
              name={trait.name}
              resourceId={trait.id}
              resourceType="traits"
              archivedAt={trait.archived_at}
              focusAfterCloseRef={menuBtnRef}
              status={restResourceStatusToGqlResourceStatus(trait.status)}
            />
          )}

          {userDefined && (
            <ResourceDeleteButton
              name={trait.name}
              resourceId={trait.id}
              resourceType="traits"
              isMenuItem
              resourceStatus={ResourceStatus.Ready} // traits don't have status, so fake it
              onDeleteComplete={() => {
                queryClient.invalidateQueries({
                  queryKey: traitQueryKeys.list,
                });
              }}
            />
          )}
        </MenuList>
      </Menu>
      {apiShortcutsModalProps.isOpen && (
        <ApiShortcutsModal
          resource={{ id: trait.id, resource_type: ResourceType.Traits }}
          onClose={apiShortcutsModalProps.onClose}
        />
      )}
    </>
  );
}
