import { Box } from "@chakra-ui/react";
import { Suspense } from "react";

import {
  CohortTraitInput,
  TraitCategory as GqlTraitCategory,
} from "../../../../__generated__/sojournerGlobalTypes";
import { Trait, useTraitSuspenseQuery } from "../../../../hooks/useTraitsQuery";
import { TraitAnalysisDimensionsChart } from "../../../traits/TraitAnalysisDimensionsChart";
import { AnimatedZapLogo } from "../../../ui/AnimatedZapLogo";
import { Blankslate } from "../../../ui/Blankslate";
import { Button } from "../../../ui/Button";
import { ErrorBoundary } from "../../../ui/ErrorBoundary";
import { ErrorText } from "../../../ui/ErrorText";
import { FormFieldset } from "../../../ui/FormFieldset";
import { InlineButtons } from "../../../ui/InlineButtons";
import { ModalV2 } from "../../../ui/ModalV2";
import { RadioGroupV2 } from "../../../ui/RadioGroupV2";
import { CohortTraitsEditor } from "./CohortTraitsEditor";

function CohortTraitSetupModalBody({
  selectedTrait,
  condition,
  onChange,
  onOptionalChange,
}: {
  selectedTrait: Trait;
  condition: CohortTraitInput;
  onChange: (condition: CohortTraitInput) => void;
  onOptionalChange: (optional: boolean) => void;
}) {
  const { trait } = useTraitSuspenseQuery(selectedTrait.id);

  if (!trait) return <p>Trait {selectedTrait.id} not found</p>;

  // slightly annoying type conversion from GQL to REST since a component uses REST
  const restTrait = {
    id: trait.id,
    name: trait.name,
    unit: trait.unit ?? undefined,
    literate: trait.literate ?? undefined,
    lookup_table: trait.lookupTable as object,
  };

  return (
    <Box p={6} display="grid" gap={6}>
      {selectedTrait.category === GqlTraitCategory.USER_DEFINED ? null : (
        <ErrorBoundary
          fallback={
            <Blankslate
              title="Failed to load trait dimensions"
              text="You can still configure the trait conditions, but the trait dimensions chart could not be loaded."
            />
          }
        >
          <Suspense fallback={<AnimatedZapLogo />}>
            <TraitAnalysisDimensionsChart trait={restTrait} />
          </Suspense>
        </ErrorBoundary>
      )}
      <FormFieldset
        legend={selectedTrait.literate ?? selectedTrait.name}
        hint={
          selectedTrait.description ??
          "Conditions that specify what values qualify for this trait."
        }
        size="sm"
      >
        <CohortTraitsEditor
          trait={trait}
          condition={condition}
          onChange={onChange}
        />
      </FormFieldset>

      <FormFieldset
        legend="Should this trait be optional?"
        hint="If marked optional this trait will be included in a single OR expression. If this is the only trait it will still be included in the cohort. If you have this trait already and it is required, further traits of the same type must be optional."
        size="sm"
      >
        <RadioGroupV2
          value={condition?.optional ? "true" : "false"}
          onChange={(value) => {
            onOptionalChange(value === "true");
          }}
          name="optional"
          options={[
            {
              label: "Require this trait",
              value: "false",
            },
            {
              label: "Make trait optional",
              value: "true",
            },
          ]}
          inline
          analyticsName="optional"
        />
      </FormFieldset>
    </Box>
  );
}

export function CohortTraitsSetupModal({
  title,
  selectedTrait,
  condition,
  onChange,
  onOptionalChange,
  onCancel,
  onBack,
  onFinish,
  backButtonLabel,
  error,
}: {
  title: string;
  selectedTrait: Trait;
  condition: CohortTraitInput;
  onChange: (condition: CohortTraitInput) => void;
  onOptionalChange: (optional: boolean) => void;
  onBack: () => void;
  backButtonLabel: string;
  onCancel: () => void;
  onFinish: () => void;
  error?: string;
}) {
  return (
    <ModalV2
      variant="flush"
      title={title}
      isOpen={true}
      onClose={onCancel}
      scrollBehavior="inside"
      width={800}
      footer={
        <Box>
          <ErrorText>{error}</ErrorText>
          <InlineButtons reverse>
            <Button variant="primary" onClick={onFinish} analyticsName="finish">
              Finish
            </Button>
            <Button variant="tertiary" onClick={onBack} analyticsName="back">
              {backButtonLabel}
            </Button>
          </InlineButtons>
        </Box>
      }
      analyticsStackName="trait-conditions"
    >
      <CohortTraitSetupModalBody
        selectedTrait={selectedTrait}
        condition={condition}
        onChange={onChange}
        onOptionalChange={onOptionalChange}
      />
    </ModalV2>
  );
}
