import { ButtonGroup } from "@chakra-ui/react";

import { ROUTE_NAMES } from "../../../constants/routeNames";
import { PageContainer } from "../../ui/PageContainer";
import { PageHeader } from "../../ui/PageHeader";
import { PageIntroText } from "../../ui/PageIntroText";
import { PageLayout } from "../../ui/PageLayout";
import { ResourceDocsLink } from "../../ui/ResourceDocsLink";
import { UsageWarningNotice } from "../../ui/UsageWarningNotice";
import { EventsTable } from "./EventsTable";

export function EventsListPage() {
  const actions = (
    <ButtonGroup>
      <ResourceDocsLink resourceType="events" />
    </ButtonGroup>
  );

  return (
    <PageLayout analyticsStackName="event-stream-list">
      <PageHeader
        title="Event streams"
        crumbs={[
          {
            label: "Event streams",
            routeName: ROUTE_NAMES.EVENTS,
          },
        ]}
        rightContent={actions}
      />
      <PageContainer size="fdy_lg">
        <UsageWarningNotice resource="streams" />
        <PageIntroText>
          Events–created in datasets–show Faraday how to recognize actions
          taking place in your data, such as purchases, renewals, click events,
          upsells, etc. Dates are often the most useful piece of data for
          events.
        </PageIntroText>
        <EventsTable />
      </PageContainer>
    </PageLayout>
  );
}
