import { useSuspenseQuery } from "@tanstack/react-query";

import { scopeQueryKeys } from "../../../../hooks/api";
import { useFdyClient } from "../../../../services/FdyClientProvider";

export function useScopeEfficacyQuery(scopeId: string) {
  const client = useFdyClient();

  return useSuspenseQuery({
    queryKey: [scopeQueryKeys.show(scopeId), "efficacy"],
    queryFn: () => client.scopes.getScopeEfficacy(scopeId),
  });
}
