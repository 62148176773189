import { PredictorsBlockedProvidersEnum } from "../outcomes/OutcomesShowPage/OutcomeDataCard";
import { Pill } from "./Pill";

function isSelfProvider(provider: string): boolean {
  // check if provider is an account uuid when provider spec is implemented
  // or perhaps there is a utility function to check if a uuid is an account uuid
  // const uuidRegex =
  // /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/;

  return provider === PredictorsBlockedProvidersEnum.SELF;
}

export const traitProvider = (trait: { category?: string }) =>
  trait.category?.startsWith("fig/") || trait.category?.startsWith("FIG_")
    ? "Faraday"
    : "User defined";

export function ProviderPill({
  provider,
  accountName,
}: {
  provider: string;
  accountName?: string;
}) {
  const providerName = traitProvider({ category: provider });

  if (provider.startsWith("fig/") || provider.startsWith("FIG_")) {
    return (
      <Pill
        sx={{
          color: "fdy_purple.500",
          backgroundColor: "fdy_purple.100",
        }}
      >
        {providerName}
      </Pill>
    );
  }

  return (
    <Pill
      sx={{
        color: "fdy_magenta.500",
        backgroundColor: "fdy_magenta.200",
      }}
    >
      {isSelfProvider(provider) ? accountName : providerName}
    </Pill>
  );
}
