import {
  Box,
  ButtonGroup,
  Input,
  InputGroup,
  InputLeftElement,
  List,
  ListItem,
  Text,
} from "@chakra-ui/react";
import { MagnifyingGlass } from "@phosphor-icons/react";
import { ChangeEvent, createRef, ReactElement, useMemo, useState } from "react";

import { TraitCategory } from "../../__generated__/sojournerGlobalTypes";
import { Trait } from "../../hooks/useTraitsQuery";
import { colors } from "../../styles/chakra-theme-v2";
import { traitCategoryToLiterate } from "../traits/traitUtils";
import { Button } from "./Button";
import { ModalV2 } from "./ModalV2";

export type TraitSearchCriteria = {
  query: string;
  categories: TraitCategory[];
};

export interface TraitSelectorModalProps {
  isOpen: boolean;
  onClose: () => void;
  select: (t: Trait) => void;
  availableTraits: Trait[];
  adding?: boolean;
  autoClose?: boolean;
}

function TraitSelectorToolbar({
  searchCriteria,
  setSearchCriteria,
  traitCategories,
}: {
  searchCriteria: TraitSearchCriteria;
  setSearchCriteria: (criteria: TraitSearchCriteria) => void;
  traitCategories: TraitCategory[];
}) {
  const handleSearchQueryChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchCriteria({
      ...searchCriteria,
      query: event.target.value,
    });
  };

  return (
    <Box
      pos="sticky"
      top="0"
      left="0"
      width="100%"
      bg="white"
      zIndex={2}
      p="16px"
    >
      <InputGroup size="sm">
        <InputGroup>
          <InputLeftElement>
            <MagnifyingGlass color={colors.fdy_gray[600]} />
          </InputLeftElement>
          <Input
            type="text"
            placeholder="Search..."
            value={searchCriteria.query}
            onChange={handleSearchQueryChange}
            autoFocus
          />
        </InputGroup>
      </InputGroup>
      <List display="inline-flex" flexWrap="wrap" mt="16px" gap="8px">
        {traitCategories.map((category) => {
          const isSelected = searchCriteria.categories.includes(category);

          const handleCategoryClick = () => {
            setSearchCriteria({
              query: searchCriteria.query,
              categories: isSelected
                ? searchCriteria.categories.filter((c) => c !== category)
                : searchCriteria.categories.concat(category),
            });
          };

          return (
            <ListItem key={category}>
              <Button
                variant="pill"
                isActive={isSelected}
                size="sm"
                onClick={handleCategoryClick}
                analyticsName="trait-selector"
              >
                {traitCategoryToLiterate(category)}
              </Button>
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
}

function TraitSelectorResultList({
  filteredTraits,
  filteredCategories,
  setSelectedTrait,
  selectedTrait,
}: {
  filteredTraits: Trait[];
  filteredCategories: TraitCategory[];
  setSelectedTrait: (t: Trait) => void;
  selectedTrait: Trait | undefined;
}) {
  const refList = filteredTraits.map(() => createRef<HTMLLIElement>());

  if (filteredTraits.length === 0) {
    return (
      <Text color={colors.fdy_gray[700]} px={5} py={2} fontSize="fdy_sm">
        No traits found
      </Text>
    );
  }

  function renderItem(trait: Trait, index: number) {
    return (
      <ListItem key={trait.name} px={6} ref={refList[index]}>
        <Box
          as="button"
          type="button"
          onClick={() => setSelectedTrait(trait)}
          data-selected={selectedTrait?.name === trait.name}
          sx={{
            borderRadius: 6,
            textAlign: "left",
            width: "100%",
            px: 4,
            py: 2,
            transition: "background-color 0.2s, color 0.2s",
            _hover: {
              bg: colors.fdy_gray[200],
            },
            _focus: {
              bg: colors.fdy_gray[100],
            },
            '&[data-selected="true"]': {
              color: colors.fdy_purple[500],
              bg: colors.fdy_gray[100],
            },
          }}
        >
          <span>{trait.literate ?? trait.name}</span>
          {trait.description ? (
            <Text fontSize="fdy_xs" color="fdy_gray.700" fontWeight="normal">
              {trait.description}
            </Text>
          ) : null}
        </Box>
      </ListItem>
    );
  }

  return (
    <List py="8px">
      {filteredCategories.map((category) => (
        <li key={category}>
          <Text fontWeight="bold" py="8px" px="16px">
            {traitCategoryToLiterate(category)}
          </Text>
          <List>
            {filteredTraits
              .filter((trait) => trait.category === category)
              .map((trait, i) => renderItem(trait, i))}
          </List>
        </li>
      ))}
    </List>
  );
}

// TODO: this should use useSelect so trait list is keyboard navigable
export function TraitSelector({
  availableTraits,
  selectedTrait,
  setSelectedTrait,
  searchCriteria: searchCriteriaFromProps,
  setSearchCriteria: setSearchCriteriaFromProps,
}: {
  availableTraits: Trait[];
  selectedTrait: Trait | undefined;
  setSelectedTrait: (t: Trait) => void;

  /* optionally controlled state search criteria */
  searchCriteria?: TraitSearchCriteria;
  setSearchCriteria?: (criteria: TraitSearchCriteria) => void;
}) {
  const [searchCriteriaLocal, setSearchCriteriaLocal] =
    useState<TraitSearchCriteria>({
      query: "",
      categories: [],
    });

  const setSearchCriteria = (criteria: TraitSearchCriteria) => {
    setSearchCriteriaFromProps?.(criteria);
    setSearchCriteriaLocal(criteria);
  };

  const searchCriteria = searchCriteriaFromProps ?? searchCriteriaLocal;

  const traitCategories: TraitCategory[] = useMemo(() => {
    // Collect all the categories that are represented in the traits that we have
    // (removing duplicates).
    return availableTraits
      .reduce((acc, curr) => {
        if (curr.category && !acc.includes(curr.category)) {
          acc.push(curr.category);
        }
        return acc;
      }, [] as TraitCategory[])
      .sort();
  }, [availableTraits]);

  const filteredTraits = useMemo(() => {
    return availableTraits.filter(
      (t) =>
        (!searchCriteria.query ||
          (t.literate ?? t.name)
            .toLowerCase()
            .includes(searchCriteria.query.trim().toLowerCase())) &&
        (searchCriteria.categories.length === 0 ||
          (t.category && searchCriteria.categories.includes(t.category)))
    );
  }, [availableTraits, searchCriteria]);

  const filteredCategories = traitCategories.filter(
    (c) =>
      (searchCriteria.categories.length === 0 ||
        searchCriteria.categories.includes(c)) &&
      (searchCriteria.query.length === 0 ||
        filteredTraits.find((t) => t.category === c))
  );

  return (
    <>
      <TraitSelectorToolbar
        searchCriteria={searchCriteria}
        setSearchCriteria={setSearchCriteria}
        traitCategories={traitCategories}
      />
      <TraitSelectorResultList
        selectedTrait={selectedTrait}
        setSelectedTrait={setSelectedTrait}
        filteredTraits={filteredTraits}
        filteredCategories={filteredCategories}
      />
    </>
  );
}

export function TraitSelectorModal({
  isOpen,
  onClose,
  select,
  availableTraits,
  adding,
  autoClose = true,
}: TraitSelectorModalProps): ReactElement {
  const [selectedTrait, setSelectedTrait] = useState<Trait | undefined>();

  function onCloseWindow() {
    setSelectedTrait(undefined);
    onClose();
  }

  function handleSelectClick() {
    if (selectedTrait) select(selectedTrait);
    if (autoClose) onCloseWindow();
  }

  const actions = (
    <ButtonGroup w="full" gap="16px">
      <Button
        variant="tertiary"
        onClick={onCloseWindow}
        width="100%"
        analyticsName="cancel"
      >
        Cancel
      </Button>
      <Button
        disabled={!selectedTrait}
        onClick={handleSelectClick}
        width="100%"
        loadingText="Adding trait..."
        isLoading={adding}
        isDisabled={adding}
        analyticsName="add"
      >
        Add trait
      </Button>
    </ButtonGroup>
  );

  return (
    <ModalV2
      variant="flush"
      scrollBehavior="inside"
      isOpen={isOpen}
      width="800px"
      onClose={onCloseWindow}
      title="Select a trait"
      footer={actions}
      analyticsStackName="select-trait-modal"
    >
      <TraitSelector
        selectedTrait={selectedTrait}
        setSelectedTrait={setSelectedTrait}
        availableTraits={availableTraits}
      />
    </ModalV2>
  );
}
