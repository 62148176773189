import { ButtonGroup } from "@chakra-ui/react";
import { Plus } from "@phosphor-icons/react";

import { ROUTE_NAMES } from "../../constants/routeNames";
import { LinkButton } from "../ui/LinkButton";
import { PageContainer } from "../ui/PageContainer";
import { PageHeader } from "../ui/PageHeader";
import { PageIntroText } from "../ui/PageIntroText";
import { PageLayout } from "../ui/PageLayout";
import { ResourceDocsLink } from "../ui/ResourceDocsLink";
import { UsageWarningNotice } from "../ui/UsageWarningNotice";
import { RecommendersTable } from "./RecommendersTable";

export function RecommendersListPage() {
  const headerCta = (
    <ButtonGroup>
      <ResourceDocsLink resourceType="recommenders" />
      <LinkButton
        routeName={ROUTE_NAMES.RECOMMENDERS_NEW}
        leftIcon={<Plus weight="bold" />}
        analyticsName="new-recommender"
      >
        New recommender
      </LinkButton>
    </ButtonGroup>
  );

  return (
    <PageLayout analyticsStackName="recommenders-list">
      <PageHeader
        title="Recommenders"
        rightContent={headerCta}
        crumbs={[
          {
            label: "Recommenders",
            routeName: ROUTE_NAMES.RECOMMENDERS,
          },
        ]}
      />
      <PageContainer size="fdy_lg">
        <UsageWarningNotice resource="recommenders" />
        <PageIntroText>
          Recommenders allow you to predict which product, service, or other
          approach is best for each individual.
        </PageIntroText>
        <RecommendersTable />
      </PageContainer>
    </PageLayout>
  );
}
