import { AnalysisDimensionsTraitBin, Trait } from "@fdy/faraday-js";
import { useSuspenseQuery } from "@tanstack/react-query";
import { BarSeries } from "@visx/xychart";

import { traitQueryKeys } from "../../hooks/api";
import { useFdyClient } from "../../services/FdyClientProvider";
import { percent } from "../../utils/formatters";
import { Blankslate } from "../ui/Blankslate";
import { AxisBottom } from "../ui/charts-v2/AxisBottom";
import { AxisLeft } from "../ui/charts-v2/AxisLeft";
import { Chart, RenderTooltipCallback } from "../ui/charts-v2/Chart";

interface Datum {
  x: string;
  y: number;
  group_percent: number;
}

// TODO: sort these in api instead.
function sortTraitAnalysisDimensions(data: AnalysisDimensionsTraitBin[]) {
  const sorted = [...data];
  if (!data || !data[0]) return data;
  sorted.sort((a, b) => {
    if (!a || !b) return 0;
    if (a.data_type === "text" && b.data_type === "text") {
      return a.value.localeCompare(b.value);
    }
    if (a.data_type === "number" && b.data_type === "number") {
      return a.min - b.min;
    }
    // we know API gives dates in YYYY-MM-DD format, so we can sort alphabetically
    if (a.data_type === "date" && b.data_type === "date") {
      return a.min.localeCompare(b.min);
    }
    return 0;
  });
  return sorted;
}

function getLookupTableValue(
  lookupTable: object | undefined,
  key: string
): string | null {
  if (!lookupTable) return null;

  if (typeof lookupTable === "string") {
    return null;
  }

  if (key in lookupTable) {
    const val = lookupTable[key as keyof object];
    if (typeof val === "string") {
      return val;
    }
  }
  return null;
}

function prepareData(
  data: AnalysisDimensionsTraitBin[],
  lookupTable: Trait["lookup_table"]
): Datum[] {
  const result: Datum[] = [];

  const resultSorted = sortTraitAnalysisDimensions(data);

  for (const d of resultSorted) {
    if (!d) continue;

    switch (d.data_type) {
      case "boolean": {
        result.push({
          x: String(d.value),
          y: d.percent,
          group_percent: d.percent,
        });
        break;
      }

      case "text": {
        const displayValue = getLookupTableValue(lookupTable, d.value);

        result.push({
          x: displayValue ?? d.value,
          y: d.percent,
          group_percent: d.percent,
        });
        break;
      }

      case "number": {
        const lookupValue = getLookupTableValue(lookupTable, String(d.min));
        const displayValue = lookupValue
          ? lookupValue
          : d.max
          ? `${String(d.min)}-${String(d.max)}`
          : `${String(d.min)}+`;
        result.push({
          x: displayValue,
          y: d.percent,
          group_percent: d.percent,
        });
        break;
      }

      case "date": {
        result.push({
          x: d.max
            ? `${String(d.min)}-${String(d.max)}`
            : `${String(d.min)}-today`,
          y: d.percent,
          group_percent: d.percent,
        });
        break;
      }

      default:
        throw new Error("Unknown type");
    }
  }

  return result;
}

const renderTooltip: RenderTooltipCallback<Datum> = ({ tooltipData }) => {
  if (!tooltipData?.nearestDatum?.key) {
    return null;
  }

  return `${tooltipData.nearestDatum.datum.x} (${percent(
    tooltipData.nearestDatum.datum.y
  )})`;
};

const useTraitAnalysisDimensionsSuspenseQuery = (id: string) => {
  const client = useFdyClient();
  return useSuspenseQuery({
    queryKey: [traitQueryKeys.show(id), "analysis", "dimensions"],
    queryFn: () => client.traits.getTraitAnalysisDimensions(id),
  });
};

export function TraitAnalysisDimensionsChart({
  trait,
}: {
  trait: Pick<Trait, "id" | "lookup_table" | "unit" | "name" | "literate">;
}) {
  const { data: dimensions } = useTraitAnalysisDimensionsSuspenseQuery(
    trait.id
  );

  // Trait preview is nice to have, but not required.
  if (!dimensions.bins.length) {
    return <Blankslate title="No trait analysis data" />;
  }

  const tableData = prepareData(dimensions.bins, trait.lookup_table);
  const traitName = trait.literate ?? trait.name;

  return (
    <Chart
      title={`${traitName} (US Population)`}
      height={300}
      xScale={{ type: "band", padding: 0.2 }}
      yScale={{ type: "linear" }}
      renderTooltip={renderTooltip}
    >
      <BarSeries
        data={tableData}
        dataKey="coverage"
        xAccessor={(d) => d.x}
        yAccessor={(d) => d.y}
      />

      <AxisLeft label="US population with this trait" tickFormat={percent} />
      <AxisBottom
        label={`${traitName}${trait.unit ? ` (${trait.unit})` : ""}`}
        // numTicks={1} // can see the rest by hovering. Prevents ugly overlap for categoricals with long names
        xScalePadding={0.2}
      />
    </Chart>
  );
}
