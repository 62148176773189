import { gql, useQuery } from "@apollo/client";
import { useMemo } from "react";

import { ACCOUNT_FEATURE_FLAGS } from "../constants/featureFlagNames";
import { isDemoOrDevEnv } from "../utils/isDemoOrDevEnv";
import { FlagsQuery } from "./__generated__/FlagsQuery";

export type FeatureFlag = keyof typeof ACCOUNT_FEATURE_FLAGS;

export const flagsQuery = gql`
  query FlagsQuery {
    account {
      features
      enabled_products
    }
  }
`;

/**
 * Given an object where keys and values are feature names, returns an object
 * where each key is the feature and the value is a boolean meaning if account
 * has that featured enabled or not.
 *
 * Default feature list is used from featureNames. Otherwise a custom list can be passed in
 * but that's mainly for testing so we don't have to update the test when features are changed.
 *
 * @example
 * const {
 *  loading: false,
 *  flags: {
 *    "LASERS": true,
 *    "JUMP_BOOTS": false,
 *    "THRUSTERS": true
 *  }
 * } = useFlags();
 *
 * This is a big TS mess to achieve:
 * - default to the feature flags file import
 * - ensure we get autocomplete for flag names
 * - accept different feature list for the test usage so tests don't rely on feature list
 *
 * It could use some TLC.
 */
export function useFlags<
  T extends typeof ACCOUNT_FEATURE_FLAGS | Record<string, string>,
  K extends keyof T
>(
  featureFlags: T = ACCOUNT_FEATURE_FLAGS as T // forcing ts around a bit
): {
  loading: boolean;
  flags: Record<K, boolean>;
  demoOrDevEnv: boolean;
} {
  const { data, loading, error } = useQuery<FlagsQuery>(flagsQuery, {
    fetchPolicy: "cache-first",
  });

  const flags = useMemo(() => {
    const { features = [], enabled_products = [] } = data?.account ?? {
      features: [],
      enabled_products: [],
    };

    const accountFlags = [...features, ...enabled_products];

    return Object.keys(featureFlags).reduce((acc, key) => {
      // Casting feature flags as object so string lookup works.
      // I tried setting `key: K` but that causes other issues.
      const flag = (featureFlags as Record<string, string>)[key];

      return {
        ...acc,
        [key]: accountFlags.includes(flag),
      };
    }, {} as Record<K, boolean>);
  }, [data]);

  if (error) throw error;

  return {
    loading,
    flags,
    demoOrDevEnv: isDemoOrDevEnv(),
  };
}
