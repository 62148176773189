import { Box, Heading, Spinner, Stack } from "@chakra-ui/react";

import { useTraitsQuery } from "../../hooks/useTraitsQuery";
import {
  allTraitPermissions,
  groupAndSortTraitsByCategories,
} from "../traits/traitUtils";
import { TagList } from "../ui/TagList";

export function ScopePayloadBreakdownTraits({
  attributes,
}: {
  attributes: string[];
}) {
  const { loadingTraits, traitsMap } = useTraitsQuery();

  if (loadingTraits) return <Spinner />;

  const traits = attributes.map(
    (attr) =>
      traitsMap[attr] ?? {
        // if for whatever reason we don't return the trait, ensure it's still visible on UI
        name: attr,
        literate: attr,
        category: null,
        permissions: allTraitPermissions,
      }
  );
  const groupedtraits = groupAndSortTraitsByCategories(traits);

  return (
    <Stack>
      {groupedtraits.map((group) => (
        <Box key={group.categoryLiterate}>
          <Heading
            as="h4"
            sx={{
              fontSize: "fdy_md",
              fontWeight: 700,
            }}
          >
            {group.categoryLiterate}
          </Heading>
          <TagList tags={group.traits.map((t) => t.literate)} />
        </Box>
      ))}
    </Stack>
  );
}
