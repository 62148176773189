import { Stack } from "@chakra-ui/react";
import { Dataset, Trait } from "@fdy/faraday-js";

import { ResourceLink } from "../ui/ResourceLink";

export function TraitDatasetLinks({
  trait,
  datasets,
}: {
  trait: Trait;
  datasets: Dataset[];
}) {
  const relevantDatasets = datasets.filter((dataset) => {
    const datasetTraits = dataset.output_to_traits;
    if (!datasetTraits) return false;
    const filteredTraits = Object.keys(datasetTraits).filter(
      (datasetTrait) => datasetTrait === trait.name
    );
    return filteredTraits.length > 0;
  });

  return (
    <Stack spacing={2}>
      {relevantDatasets.map((dataset) => (
        <ResourceLink resource={dataset} key={dataset.id} />
      ))}
    </Stack>
  );
}
