/**
 * This file contains pieces to create and use a 2nd Apollo client,
 * intended for consuming the Sojourner Graphql endpoint.
 */

import {
  ApolloClient,
  ApolloLink,
  DocumentNode,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
  OperationVariables,
  SuspenseQueryHookOptions,
  TypedDocumentNode,
  useLazyQuery,
  useMutation,
  useQuery,
  useSuspenseQuery,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { RetryLink } from "@apollo/client/link/retry";
import { MockedResponse, MockLink } from "@apollo/client/testing";
import { useQueryClient } from "@tanstack/react-query";
import { FieldNode } from "graphql";
import { createContext, useContext, useEffect, useState } from "react";
import * as React from "react";
import Rollbar from "rollbar";

import { useAuth } from "../components/ui/AuthProvider/AuthProvider";
import {
  errorHasStatusCode,
  logErrorToRollbar,
} from "../components/ui/ErrorBoundary/errorUtils";
import { useRollbar } from "../components/ui/RollbarProvider";
import { env } from "../env";
import { useApiErrorToaster } from "../hooks/useApiErrorToaster";
import { isDemoOrDevEnv } from "../utils/isDemoOrDevEnv";
import { isPlainObject } from "../utils/isPlainObject";
import { createErrorMiddleware } from "./ApolloGQLService";
import { AuthService } from "./authService";
import { connectionOptionsAliases } from "./connectionOptions";
import { getApiBaseUrl } from "./getApiBaseUrl";

export function createLoggerLink(name: string): ApolloLink {
  return new ApolloLink((operation, forward) => {
    return forward(operation).map((result) => {
      const { variables } = operation;
      console.groupCollapsed(
        `%c Apollo Logger(${name}): ${operation.operationName}`,
        "color: cyan; font-weight: bold"
      );
      console.log(JSON.stringify({ variables, result }, null, 2));
      console.groupEnd();

      return result;
    });
  });
}

/**
 * Create an apollo client for Sojourner/Faraday API/SDK graphql endpoint
 */
function createSojournerClient({
  auth,
  logger,
}: {
  auth: AuthService;
  logger: Console | Rollbar;
}) {
  const uri = getApiBaseUrl() + "/gql";

  const httpLink = new HttpLink({ uri });

  const authMiddleware = setContext(async () => ({
    headers: {
      authorization: `Bearer ${await auth.getToken()}`,
    },
  }));

  const cache = new InMemoryCache();

  const retry = new RetryLink({
    delay: {
      initial: 300,
      max: Infinity,
      jitter: true,
    },
    attempts: {
      max: 5,
      // Retry on network errors
      retryIf: (error) => !!error,
    },
  });

  const links = [
    retry,
    authMiddleware,
    createErrorMiddleware(auth, logger),
    httpLink,
  ];

  if (isDemoOrDevEnv()) {
    links.unshift(createLoggerLink("sojourner"));
  }

  return new ApolloClient({
    cache,
    connectToDevTools: env.ENVIRONMENT === "development",
    link: ApolloLink.from(links),
  });
}

let sojournerApolloClient: ApolloClient<NormalizedCacheObject> | undefined;

export function useSojournerClientFactory() {
  const auth = useAuth();
  const rollbar = useRollbar();

  if (!sojournerApolloClient) {
    return createSojournerClient({
      auth,
      logger: env.ENVIRONMENT === "production" ? rollbar : console,
    });
  }

  return sojournerApolloClient;
}

// set up a custom context provider for the 2nd apollo client
// we don't want to use the default ApolloProvider since other useQueries would try to use it.

export const SojournerApolloClientContext = createContext<
  ApolloClient<NormalizedCacheObject> | undefined
>(undefined);

export function SojournerApolloProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const client = useSojournerClientFactory();
  return (
    <SojournerApolloClientContext.Provider value={client}>
      {children}
    </SojournerApolloClientContext.Provider>
  );
}

export function useSojournerApolloClient() {
  const client = useContext(SojournerApolloClientContext);
  if (!client) {
    throw new Error("apollo client context is missing");
  }
  return client;
}

/**
 * Alias for useQuery that pulls our 2nd apollo client from a different context value
 */
export const useSojournerLazyQuery: typeof useLazyQuery = (doc, params) => {
  const client = useSojournerApolloClient();

  return useLazyQuery(doc, {
    ...params,
    client,
  });
};

type TDataType = Record<string, unknown>;

/**
 * Alias for useQuery that pulls our 2nd apollo client from a different context value
 */
export const useSojournerQuery: typeof useQuery = (doc, params) => {
  const client = useSojournerApolloClient();
  const rollbar = useRollbar();
  const [data, setData] = useState<typeof query.data>();

  const query = useQuery(doc, {
    ...params,
    client,
  });

  let hasReportableError = false;
  if (query.error) {
    // If polling and we get a 502 (vault 404), stop polling but don't report the error
    // and don't update the list of scopes to render.
    if (params?.pollInterval && data && errorHasStatusCode(query.error, 502)) {
      console.log("saw 502 with cached data, stop polling");
      // since we're swallowing the error, we need to manually log it to rollbar:
      logErrorToRollbar({ error: query.error, rollbar, level: "error" });
      query.stopPolling();
    } else {
      hasReportableError = true;
    }
  }
  useEffect(() => {
    if (params?.pollInterval && query.data) {
      setData(query.data);
    }
  }, [query.data]);

  if (!params?.pollInterval || (query.data && !query.error)) {
    // Return the query normally if we're not polling or it executed successfully without error.
    //  - On the first render, query.data, state won't be set yet, but we want to return the data
    //    returned by the query.
    //  - If polling happens and an error pops up, query.data will still be populated, so we want
    //    to fall through to check whether we want to report the error or not (with hasReportableError).
    return query;
  }

  const error = hasReportableError ? query.error : undefined;

  // throw error to nearest boundary
  if (error) throw error;

  return {
    ...query,
    data,
  };
};

export function useSojournerSuspenseQuery<
  TData,
  TVars extends OperationVariables
>(
  doc: DocumentNode | TypedDocumentNode<TData, TVars>,
  params: SuspenseQueryHookOptions<TData, TVars>
) {
  const client = useSojournerApolloClient();

  return useSuspenseQuery<TData, TVars>(doc, {
    ...params,
    client,
  });
}

/**
 * Alias for useQuery that pulls our 2nd apollo client from a different context value.
 *
 * Also performs automatic alias substitution on the options object.
 * For datasets, connections, and targets.
 */
export const useSojournerQueryWithAliasSubstitution: typeof useQuery = (
  doc,
  params
) => {
  const client = useSojournerApolloClient();
  const { data, ...other } = useQuery(doc, {
    ...params,
    client,
  });
  return {
    data:
      typeof data === "object"
        ? (convertOptionsAliases(data as TDataType) as typeof data)
        : data,
    ...other,
  };
};

/** Reverse lookup of connectionOptionsAliases */
const reverseAliasLookup: Record<string, string> = {};
Object.entries(connectionOptionsAliases).forEach(([key, value]) => {
  reverseAliasLookup[value] = key;
});

/**
 * Recursively checks if any property of data has an object with an 'options' key. If so,
 * convert all options present in the values of the lookup object connectionOptionsAliases
 * to their unaliased versions (lookup on a reverse map of connectionOptionsAliases):
 * This is because the API expects the aliased version, but the SDK expects the unaliased version
 * and we don't want to have to write a bunch of boilerplate to convert between the two.
 * */
function convertOptionsAliases(
  obj: undefined | TDataType
): undefined | TDataType {
  if (!obj) return;
  const memo: TDataType = {};
  Object.entries(obj).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      memo[key] = value.map((v) => convertOptionsAliases(v));
    } else if (key === "options" && value && typeof value === "object") {
      const options = { ...value } as TDataType;
      Object.entries(options).forEach(([optionKey, optionValue]) => {
        if (optionKey in reverseAliasLookup) {
          options[reverseAliasLookup[optionKey]] = optionValue;
          delete options[optionKey];
        }
      });
      memo.options = options;
    } else if (typeof value === "object") {
      memo[key] = convertOptionsAliases(value as TDataType);
    } else {
      memo[key] = value;
    }
  });
  return memo;
}

/**
 * Deeply traverse `params` to detect if any leaf values are an empty string.
 * If so, replace with `null` (for PATCH) or `undefined` (for POST) so the API
 * will correctly interpret this as an unset value.
 *
 * ASSUMPTION: we never want to send an empty string to the API.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function replaceEmptyStrings(obj: unknown, replaceWith: null | undefined) {
  const keysWithEmptyStrings: string[] = [];

  if (!isPlainObject(obj)) return keysWithEmptyStrings;

  Object.keys(obj).forEach((key) => {
    let val = obj[key];

    if (isPlainObject(val)) {
      return replaceEmptyStrings(val, replaceWith);
    }

    if (typeof val === "string") {
      if (val.trim() === "") {
        keysWithEmptyStrings.push(key);
        obj[key] = replaceWith;
      } else if (/:""/.test(val)) {
        // JSON object with empty string value
        try {
          const parsed = JSON.parse(val);
          keysWithEmptyStrings.push(
            ...replaceEmptyStrings(parsed, replaceWith)
          );
          val = JSON.stringify(parsed);
        } catch (e) {
          // ignore
        }
      }
    }
  });

  return keysWithEmptyStrings;
}

/**
 * Returns `true` if the GraphQL mutation is a PATCH operation.
 *
 * Checks whether "applicationJsonMergePatchInput" is present in the mutation.
 */
function mutationIsPatch(doc: DocumentNode | TypedDocumentNode): boolean {
  const operationDefinition = doc.definitions[0];
  if (operationDefinition.kind === "OperationDefinition") {
    const selectionSet = operationDefinition.selectionSet;
    if (selectionSet && selectionSet.selections.length) {
      const selectionArguments = (
        selectionSet.selections.filter(
          (s) => s.kind === "Field"
        )[0] as FieldNode
      ).arguments;
      return (
        selectionArguments?.some(
          (arg) =>
            arg.kind === "Argument" &&
            arg.name.value === "applicationJsonMergePatchInput"
        ) ?? false
      );
    }
  }
  return false;
}

/**
 * Alias for useMutation that pulls our 2nd apollo client from a different context value
 */
export const useSojournerMutation: typeof useMutation = (
  mutation,
  mutationOptions
) => {
  const client = useSojournerApolloClient();
  const queryClient = useQueryClient();

  const [mutationFn, mutationResult] = useMutation(mutation, {
    ...mutationOptions,
    client,
    onCompleted: (...args) => {
      mutationOptions?.onCompleted?.(...args);

      // Invalidate all react-queries on mutation completion
      // since we don't know which queries might be affected.
      queryClient.invalidateQueries();
    },
  });

  useApiErrorToaster(mutationResult.error);

  /**
   * Empty strings are a bug. But rather than expose to users, we log warnings
   * and silently replace with null (for PATCH) or undefined (for POST).
   */
  const mutationFnWrapped: typeof mutationFn = (options?) => {
    if (!options?.variables) return mutationFn(options);
    const keysWithEmptyStrings = replaceEmptyStrings(
      options.variables,
      mutationIsPatch(mutation) ? null : undefined
    );
    if (keysWithEmptyStrings.length) {
      // log to rollbar
      console.warn(
        `[GraphQL warning]: Empty strings in mutation variables: ${keysWithEmptyStrings.join(
          ", "
        )}. Location: ${mutation.loc?.source.name}}`
      );
    }
    return mutationFn(options);
  };

  return [mutationFnWrapped, mutationResult];
};

/**
 * Create a mock Apollo client for testing
 */
export function createMockSojournerApolloClient({
  mocks,
  addTypename,
}: {
  mocks: ReadonlyArray<MockedResponse>;
  addTypename: boolean;
}) {
  const mockLink = new MockLink(mocks || [], addTypename);

  const links: ApolloLink[] = [mockLink];

  if (process.env.MOCK_APOLLO_DEBUG) {
    links.unshift(createLoggerLink("sojourner"));
  }

  const client = new ApolloClient({
    cache: new InMemoryCache({
      addTypename,
    }),
    link: ApolloLink.from(links),
    // Something about apollo in test environment is causing
    // errors to be thrown to boundaries/etc and not returned in normal hook result.
    // https://github.com/apollographql/apollo-client/issues/7167#issuecomment-848619726
    defaultOptions: {
      mutate: {
        errorPolicy: "all",
        // Note: leave fetch policy alone, so tests can test cache updates too
        // see https://github.com/apollographql/apollo-client/issues/3401
        // fetchPolicy: "no-cache",
      },
      query: {
        errorPolicy: "all",
        // fetchPolicy: "no-cache",
      },
    },
  });

  return client;
}

/**
 * Use in place of Apollo's MockProvider for testing components which use Sojourner queries.
 *
 * Somewhat related docs https://www.apollographql.com/docs/react/development-testing/testing/#example
 */
export function MockedSojournerProvider({
  mocks,
  addTypename = false,
  client,
  children,
}: {
  mocks: ReadonlyArray<MockedResponse>;
  addTypename?: boolean;
  children: React.ReactNode;
  client?: ApolloClient<NormalizedCacheObject>;
}) {
  const mockClient =
    client ?? createMockSojournerApolloClient({ mocks, addTypename });

  return (
    <SojournerApolloClientContext.Provider value={mockClient}>
      {children}
    </SojournerApolloClientContext.Provider>
  );
}
