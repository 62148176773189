import { Box, Heading, Text } from "@chakra-ui/react";
import { Stream } from "@fdy/faraday-js";
import React from "react";

import { colors } from "../../../styles/chakra-theme-v2";
import { number } from "../../../utils/formatters";
import { CardV2 } from "../../ui/Card/CardV2";
import { PendingValue } from "../../ui/PendingValue";
import { PendingEventDate } from "./PendingEventDate";

const SummaryItem = ({
  title,
  children,
}: {
  title: string;
  children: React.ReactNode;
}) => {
  return (
    <Box
      sx={{
        flex: 1,
        padding: 4,
        bg: colors.fdy_gray[100],
        borderRadius: "md",
        border: "1px solid",
        borderColor: colors.fdy_gray[200],
      }}
    >
      <Heading as="h3" sx={{ fontSize: "fdy_md", fontWeight: "bold" }}>
        {title}
      </Heading>
      <Text fontSize="fdy_xl" fontWeight="normal">
        {children}
      </Text>
    </Box>
  );
};

export const EventStreamSummaryCard = ({ stream }: { stream: Stream }) => {
  return (
    <CardV2 title="Summary">
      <Box
        sx={{
          display: "flex",
          gap: 8,
        }}
      >
        <SummaryItem title="First event">
          <PendingEventDate
            stream={stream}
            renderDate={(stream) => stream.oldest_date}
          />
        </SummaryItem>
        <SummaryItem title="Event count">
          <PendingValue value={stream.event_count} formatter={number} />
        </SummaryItem>
        <SummaryItem title="Most recent event">
          <PendingEventDate
            stream={stream}
            renderDate={(stream) => stream.newest_date}
          />
        </SummaryItem>
      </Box>
    </CardV2>
  );
};
