import { Stream } from "@fdy/faraday-js";
import React from "react";

import { date } from "../../../utils/formatters";
import { PendingValue } from "../../ui/PendingValue";

export function PendingEventDate({
  stream,
  renderDate,
}: {
  stream: Stream;
  renderDate: (stream: Stream) => string | undefined;
}) {
  // streams without a datetime property will never have a date to render
  const hasDates = stream.properties?.datetime;
  const tooltipText = "Value will populate on next successful build";

  const value = renderDate(stream);

  return (
    <PendingValue
      value={value}
      formatter={date}
      placeholder={hasDates ? "Pending..." : "N/A"}
      tooltip={
        hasDates && value
          ? tooltipText
          : "Unavailable for dateless event streams"
      }
    />
  );
}
