import { Dataset, ResourceType, Stream } from "@fdy/faraday-js";
import { ColumnDef } from "@tanstack/react-table";
import { useMemo } from "react";

import { ROUTE_NAMES } from "../../../constants/routeNames";
import {
  useDatasetListSuspenseQuery,
  useStreamListSuspenseQuery,
} from "../../../hooks/api";
import { largeNumber } from "../../../utils/formatters";
import { PendingValue } from "../../ui/PendingValue";
import { PopoverInfoPill, PopoverInfoPillPart } from "../../ui/PopoverInfoPill";
import { ResourceIcon } from "../../ui/ResourceIcon";
import { ResourceLink } from "../../ui/ResourceLink";
import { ResourceStatusBadge } from "../../ui/ResourceStatus/ResourceStatusBadge";
import { ResourceTable } from "../../ui/ResourceTable";
import { TimeAgo } from "../../ui/TimeAgo";
import { EventsActionsMenu } from "../EventsActionsMenu";
import { EventStreamDatasetsTable } from "../EventShowPage/EventStreamDatasetContributionCard";
import { EventStreamPropertiesTable } from "../EventShowPage/EventStreamPropertiesCard";
import { PendingEventDate } from "../EventShowPage/PendingEventDate";

export const findRelevantDatasetsByStream = (
  stream: Stream,
  datasets: Dataset[]
) => {
  return datasets.filter((dataset) => {
    return Object.values(dataset.output_to_streams ?? {}).filter(
      (st) => st.stream_id === stream.id
    ).length;
  });
};

const StreamDatasetsPill = ({ stream }: { stream: Stream }) => {
  const datasetsQuery = useDatasetListSuspenseQuery(); // we can rely on deduping here, so rendering multiple times is fine
  const relevantDatasets = findRelevantDatasetsByStream(
    stream,
    datasetsQuery.data ?? []
  );

  if (!relevantDatasets.length) return null;

  return (
    <PopoverInfoPill popover={<EventStreamDatasetsTable stream={stream} />}>
      <PopoverInfoPillPart
        icon={ResourceIcon.datasets}
        value={relevantDatasets.length}
      />
    </PopoverInfoPill>
  );
};

const StreamPropertiesPill = ({ stream }: { stream: Stream }) => {
  const properties = Object.entries(stream.properties ?? {});

  if (!properties.length) return null;

  return (
    <PopoverInfoPill popover={<EventStreamPropertiesTable stream={stream} />}>
      <PopoverInfoPillPart
        icon={ResourceIcon.traits}
        value={properties.length}
      />
    </PopoverInfoPill>
  );
};

const BLANK_SLATE_PROPS = {
  title: "No event streams found",
  text: "Event Streams are emitted by Datasets.",
  button: {
    routeName: ROUTE_NAMES.DATASETS,
    children: "Use Datasets to add Event Streams.",
  },
};

export function EventsTable() {
  const streamsQuery = useStreamListSuspenseQuery();

  const tableColumns: ColumnDef<Stream>[] = useMemo(
    () => [
      {
        accessorKey: "name",
        header: "Name",
        size: 200,
        cell: ({ row }) => (
          <ResourceLink resource={row.original} sx={{ fontWeight: "bold" }} />
        ),
      },
      {
        id: "datasets",
        header: "Datasets",
        size: 100,
        cell: ({ row }) => <StreamDatasetsPill stream={row.original} />,
      },
      {
        id: "properties",
        header: "Properties",
        size: 100,
        cell: ({ row }) => <StreamPropertiesPill stream={row.original} />,
      },
      {
        accessorKey: "event_count",
        header: "Event count",
        cell: ({ row }) => (
          <PendingValue
            value={row.original.event_count}
            formatter={largeNumber}
          />
        ),
        sortingFn: "basic",
      },
      {
        accessorKey: "newest_date",
        header: "Most recent event",
        size: 200,
        cell: ({ row }) => (
          <PendingEventDate
            stream={row.original}
            renderDate={(stream) => stream.newest_date}
          />
        ),
        sortingFn: "datetime",
      },
      {
        accessorKey: "status",
        header: "Status",
        cell: ({ row }) => <ResourceStatusBadge resource={row.original} />,
        sortingFn: "basic",
      },
      {
        accessorKey: "created_at",
        header: "Created",
        cell: ({ row }) => <TimeAgo date={row.original.created_at} />,
        sortingFn: "datetime",
      },
      {
        accessorKey: "last_updated_config_at",
        header: "Modified",
        cell: ({ row }) => (
          <TimeAgo date={row.original.last_updated_config_at} />
        ),
        sortingFn: "datetime",
      },
      {
        accessorKey: "last_updated_output_at",
        header: "Processed",
        cell: ({ row }) => (
          <TimeAgo date={row.original.last_updated_output_at} />
        ),
        sortingFn: "datetime",
      },
      {
        id: "actions",
        header: "Actions",
        size: 64,
        enableSorting: false,
        cell: ({ row }) => <EventsActionsMenu event={row.original} />,
      },
    ],
    []
  );

  return (
    <ResourceTable<Stream>
      resourceType={ResourceType.Streams}
      blankslate={BLANK_SLATE_PROPS}
      data={streamsQuery.data}
      columns={tableColumns}
      defaultSort={[
        {
          id: "created_at",
          desc: true,
        },
      ]}
    />
  );
}
