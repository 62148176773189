import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
} from "@chakra-ui/react";
import {
  Trait,
  TraitCategory,
  TraitPermission,
  TraitTier,
} from "@fdy/faraday-js";
import { MagnifyingGlass, X } from "@phosphor-icons/react";
import debounce from "lodash/debounce";
import pick from "lodash/pick";
import upperFirst from "lodash/upperFirst";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useRoute, useRouter } from "react-router5";

import { Button } from "../ui/Button";
import { FormField } from "../ui/FormField";
import { traitPermissionInfo } from "./TraitPermissionsPills";
import { traitCategoryToLiterate, traitLiterate } from "./traitUtils";

export enum ProvidersOptions {
  faraday = "faraday",
  user = "user",
}

export type TraitFilterOptions = {
  search: string | null;
  provider: ProvidersOptions | null;
  category: TraitCategory | null;
  tier: TraitTier | null;
  permissions: TraitPermission | null;
};

export const traitParamKeys: (keyof TraitFilterOptions)[] = [
  "search",
  "provider",
  "category",
  "tier",
  "permissions",
];

type TraitFiltersProps = {
  filters: TraitFilterOptions;
  setFilters: Dispatch<SetStateAction<TraitFilterOptions>>;
  hasAnyFilter: boolean;
  filteredTraits: Trait[];
  clearAllFilters: () => void;
};

export function useTraitFilters(traits: Trait[]): TraitFiltersProps {
  const router = useRouter();
  const { route } = useRoute();

  // Initialize filters from route params
  const [filters, setFilters] = useState<TraitFilterOptions>(
    pick(route.params, traitParamKeys) as TraitFilterOptions
  );

  // Update route params when filters change.
  // Debounce to avoid updating route params on every key press.
  const updateParams = useCallback(
    debounce((newFilters: TraitFilterOptions) => {
      const routerState = router.getState();
      const newParams = { ...routerState?.params };

      Object.entries(newFilters).forEach(([key, value]) => {
        if (value) {
          newParams[key] = value;
        } else {
          delete newParams[key];
        }
      });

      router.navigate(routerState?.name, newParams, {
        replace: true,
      });
    }, 200),
    [router]
  );

  // Update filters when route changes
  useEffect(() => {
    updateParams(filters);
  }, [filters, updateParams]);

  const hasAnyFilter = useMemo(() => {
    return Object.values(filters).some(
      (value) => value !== null && value !== ""
    );
  }, [filters]);

  const clearAllFilters = useCallback(() => {
    setFilters({
      search: null,
      provider: null,
      category: null,
      tier: null,
      permissions: null,
    });
  }, [setFilters]);

  const filteredTraits = useMemo(() => {
    return traits.filter((trait) => {
      const matchesSearch =
        !filters.search ||
        traitLiterate(trait)
          .toLowerCase()
          .includes(filters.search.toLowerCase());

      const matchesPermissions =
        !filters.permissions ||
        trait.permissions?.includes(filters.permissions);

      const matchesProvider =
        !filters.provider ||
        (filters.provider === ProvidersOptions.user &&
          trait.category === TraitCategory.UserDefined) ||
        (filters.provider === ProvidersOptions.faraday &&
          trait.category !== TraitCategory.UserDefined);

      const matchesCategory =
        !filters.category || trait.category === filters.category;

      const matchesTier = !filters.tier || trait.tier === filters.tier;

      return (
        !trait.deprecated &&
        matchesSearch &&
        matchesPermissions &&
        matchesProvider &&
        matchesCategory &&
        matchesTier
      );
    });
  }, [traits, filters]);

  return {
    filters,
    setFilters,
    hasAnyFilter,
    filteredTraits,
    clearAllFilters,
  };
}

export function TraitFilters({
  filters,
  setFilters,
  hasAnyFilter,
  clearAllFilters,
}: TraitFiltersProps) {
  return (
    <Flex gap={4} mb={4} alignItems="flex-end">
      <Box>
        <FormControl>
          <FormLabel>Search for traits</FormLabel>
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <MagnifyingGlass />
            </InputLeftElement>
            <Input
              placeholder="Type something"
              value={filters.search ?? ""}
              onChange={(e) => {
                setFilters((prev) => ({
                  ...prev,
                  search: e.target.value,
                }));
              }}
            />
          </InputGroup>
        </FormControl>
      </Box>

      <Box>
        <FormControl>
          <FormLabel>Provider</FormLabel>
          <Select
            value={filters.provider ?? ""}
            onChange={(e) => {
              setFilters((prev) => ({
                ...prev,
                provider: e.target.value as ProvidersOptions,
              }));
            }}
          >
            <option value="">All providers</option>
            <option value={ProvidersOptions.faraday}>Faraday</option>
            <option value={ProvidersOptions.user}>User defined</option>
          </Select>
        </FormControl>
      </Box>
      <Box>
        <FormControl>
          <FormLabel>Category</FormLabel>
          <Select
            value={filters.category ?? ""}
            onChange={(e) => {
              setFilters((prev) => ({
                ...prev,
                category: e.target.value
                  ? (e.target.value as TraitCategory)
                  : null,
              }));
            }}
          >
            <option value="">All categories</option>
            {Object.entries(TraitCategory).map(([_key, value]) => {
              return (
                <option value={value} key={value}>
                  {traitCategoryToLiterate(value)}
                </option>
              );
            })}
          </Select>
        </FormControl>
      </Box>
      <Box>
        <FormControl>
          <FormLabel>Tier</FormLabel>
          <Select
            value={filters.tier ?? ""}
            onChange={(e) => {
              setFilters((prev) => ({
                ...prev,
                tier: e.target.value ? (e.target.value as TraitTier) : null,
              }));
            }}
          >
            <option value="">All tiers</option>
            {Object.values(TraitTier).map((tier) => {
              return (
                <option value={tier} key={tier}>
                  {upperFirst(tier)}
                </option>
              );
            })}
          </Select>
        </FormControl>
      </Box>
      <Box>
        <FormField label="Permissions">
          <Select
            value={filters.permissions ?? ""}
            onChange={(e) => {
              setFilters((prev) => ({
                ...prev,
                permissions: e.target.value
                  ? (e.target.value as TraitPermission)
                  : null,
              }));
            }}
          >
            <option value="">All permissions</option>
            {Object.values(TraitPermission).map((permission) => {
              return (
                <option value={permission} key={permission}>
                  {traitPermissionInfo[permission].label}
                </option>
              );
            })}
          </Select>
        </FormField>
      </Box>
      {hasAnyFilter && (
        <Button variant="tertiary" leftIcon={<X />} onClick={clearAllFilters}>
          Clear filters
        </Button>
      )}
    </Flex>
  );
}
