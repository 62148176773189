import {
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
} from "@chakra-ui/react";
import { ResourceType, Stream } from "@fdy/faraday-js";
import { DotsThreeOutline } from "@phosphor-icons/react";

import { ActionMenuChiefLink } from "../ui/ActionMenuChiefLink";
import { ApiShortcutsModal } from "../ui/ApiDocs/ApiShortcutsModal";
import { ArchiveMenuButton } from "../ui/ResourceArchiveButton";
import { ResourceDeleteButton } from "../ui/ResourceDeleteButton";
import { restResourceStatusToGqlResourceStatus } from "../ui/ResourceStatus/normalizeResourceDetails";

/**
 * Renders a dropdown menu with various actions a user can take on a given Event.
 */
export function EventsActionsMenu({ event }: { event: Stream }) {
  const apiShortcutsModalProps = useDisclosure();
  return (
    <>
      <Menu>
        <MenuButton
          as={IconButton}
          aria-label="Toggle Event stream actions"
          icon={<DotsThreeOutline weight="fill" />}
          variant="icon"
          color="fdy_gray.600"
          size="sm"
        />

        <MenuList>
          <MenuItem onClick={apiShortcutsModalProps.onOpen}>
            API shortcuts
          </MenuItem>
          <ActionMenuChiefLink resource={event} />
          <ArchiveMenuButton
            name={event.name}
            resourceType="streams"
            resourceId={event.id}
            archivedAt={event.archived_at}
            status={restResourceStatusToGqlResourceStatus(event.status)}
          />
          <ResourceDeleteButton
            isMenuItem
            resourceType="streams"
            resourceId={event.id}
            name={event.name}
            resourceStatus={event.status}
          />
        </MenuList>
      </Menu>
      {apiShortcutsModalProps.isOpen && (
        <ApiShortcutsModal
          resource={{
            id: event.id,
            resource_type: ResourceType.Streams,
          }}
          onClose={apiShortcutsModalProps.onClose}
        />
      )}
    </>
  );
}
