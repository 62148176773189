import { PaginationState } from "@tanstack/react-table";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useRouter } from "react-router5";

const DEFAULT_PAGE_SIZE = 10;

/**
 * Sync the page index in the URL with the pagination state.
 * Useful for when the user navigates back to the page and expects to see the same page.
 *
 * Page is also stored as internal state because we don't emit the page number for page 1
 */
export const useUrlParamPagination = (): {
  paginationState: PaginationState;
  setPagination: Dispatch<SetStateAction<PaginationState>>;
} => {
  const router = useRouter();
  const page = router.getState()?.params.page;

  const [paginationState, setPagination] = useState<PaginationState>({
    pageIndex: page ? parseInt(page, 10) - 1 : 0,
    pageSize: DEFAULT_PAGE_SIZE,
  });

  useEffect(() => {
    const routerState = router.getState();

    router.navigate(
      routerState?.name,
      {
        ...routerState?.params,
        page:
          paginationState.pageIndex === 0
            ? undefined
            : paginationState.pageIndex + 1,
      },
      {
        replace: true,
      }
    );
  }, [paginationState.pageIndex]);

  return { paginationState, setPagination };
};
