import { ROUTE_NAMES } from "../constants/routeNames";
import { components } from "../sojourner-oas-types";

export type ResourceType = components["schemas"]["ResourceType"];

/**
 * Given a resource type and id, return the route name and params
 */
export function createResourceRoute({
  id,
  upstreamId,
  type,
}: {
  id: string;
  upstreamId?: string | null;
  type: ResourceType;
}): { routeName: string; params?: Record<string, string> } {
  switch (type) {
    case "scopes":
      return {
        routeName: ROUTE_NAMES.PIPELINES_VIEW,
        params: { id },
      };
    case "datasets":
      return {
        routeName: ROUTE_NAMES.DATASETS_DEFINITION,
        params: { id },
      };
    case "streams":
      return {
        routeName: ROUTE_NAMES.EVENTS_SHOW,
        params: { id },
      };
    case "traits":
      return {
        routeName: ROUTE_NAMES.TRAITS_SHOW,
        params: { id },
      };
    case "cohorts":
      return {
        routeName: ROUTE_NAMES.COHORTS_SHOW,
        params: { cohort: id },
      };
    case "outcomes":
      return {
        routeName: ROUTE_NAMES.OUTCOMES_DEFINITION,
        params: { outcome: id },
      };
    case "persona_sets":
      return {
        routeName: ROUTE_NAMES.PERSONAS_ROSTER,
        params: { roster: id },
      };
    case "connections":
      return {
        routeName: ROUTE_NAMES.CONNECTIONS_SHOW,
        params: { id },
      };
    case "places":
      return {
        routeName: ROUTE_NAMES.LOCATIONS,
      };
    case "targets":
      if (upstreamId) {
        return {
          routeName: ROUTE_NAMES.PIPELINES_VIEW,
          params: { id: upstreamId },
        };
      }
      return {
        routeName: ROUTE_NAMES.PIPELINES,
      };
    case "recommenders":
      return {
        routeName: ROUTE_NAMES.RECOMMENDERS_SHOW,
        params: { id },
      };
    default:
      throw new Error(`Unknown resource type: ${type}`);
  }
}
