import { Flex, Text } from "@chakra-ui/react";
import {
  ResourceType,
  ScopeEfficacyOutcomeMonthPerformedEnum,
} from "@fdy/faraday-js";
import { CheckCircle, IconProps, XCircle } from "@phosphor-icons/react";

import { colors } from "../../../../styles/chakra-theme-v2";
import { ResourceLink } from "../../../ui/ResourceLink";

export function EfficacyPerformedIcon({
  performed,
}: {
  performed: ScopeEfficacyOutcomeMonthPerformedEnum;
}) {
  const baseProps: IconProps = {
    size: 24,
    weight: "fill",
  };

  if (
    performed === ScopeEfficacyOutcomeMonthPerformedEnum.Equal ||
    performed === ScopeEfficacyOutcomeMonthPerformedEnum.GreaterThan
  ) {
    return <CheckCircle color={colors.fdy_green[600]} {...baseProps} />;
  }
  return <XCircle color={colors.fdy_red[500]} {...baseProps} />;
}

export function EfficacyResult({
  performed,
  outcome,
}: {
  performed: ScopeEfficacyOutcomeMonthPerformedEnum;
  outcome: { id: string };
}) {
  const outcomeLink = (
    <ResourceLink
      resource={{
        id: outcome.id,
        resource_type: ResourceType.Outcomes,
        name: "performance reporting",
      }}
    />
  );

  const performedResult: Record<
    ScopeEfficacyOutcomeMonthPerformedEnum,
    {
      title: string;
      text: JSX.Element;
    }
  > = {
    greater_than: {
      title: "Our predictions were right",
      text: (
        <>
          The distribution below performed as good or better than what was
          expected in its {outcomeLink}.
        </>
      ),
    },
    equal: {
      title: "Our predictions were right",
      text: (
        <>
          The distribution below performed as good as what was expected in its{" "}
          {outcomeLink}.
        </>
      ),
    },
    less_than: {
      title: "Something’s not right",
      text: (
        <>
          The distribution below performed worse than what we expected in its{" "}
          {outcomeLink}
        </>
      ),
    },
  };

  const { title, text } = performedResult[performed];

  return (
    <Flex gap={2} mb={4}>
      <EfficacyPerformedIcon performed={performed} />
      <Text maxW={600}>
        <strong>{title}</strong>. {text}
      </Text>
    </Flex>
  );
}
