import { Link } from "@chakra-ui/react";
import { BookOpen } from "@phosphor-icons/react";
import { ReactNode } from "react";

import { IconWithText } from "./IconWithText";

export const DocLink = ({
  href,
  children,
}: {
  href: string;
  children: ReactNode;
}) => (
  <Link href={href}>
    <IconWithText>
      <BookOpen size={24} />
      {children}
    </IconWithText>
  </Link>
);
