import { ResourceType } from "@fdy/faraday-js";
import { Warning } from "@phosphor-icons/react";
import capitalize from "lodash/capitalize";
import React from "react";

import { gqlTypenameToResourceType } from "../../../constants/gqlTypenameToResourceType";
import { typeAlias } from "../../../constants/typeAlias";
import { date } from "../../../utils/formatters";
import { Notice } from "../Notice";

type Resource = { id: string; __typename: string; archivedAt: string | null };

export function ResourceArchivedAlert({ resource }: { resource: Resource }) {
  const resourceType = gqlTypenameToResourceType[resource.__typename];
  const resourceName = typeAlias[resourceType as ResourceType];
  const capResourceName = capitalize(resourceName);

  if (resource.archivedAt) {
    return (
      <Notice
        variant="warning"
        title={`Archived.`}
        icon={Warning}
        description={`${capResourceName} was archived on ${date.dateAndTime(
          resource.archivedAt
        )}. Use the ... menu to restore it.`}
      />
    );
  }

  return null;
}
